<template>
  <span class="slider-container">
    <font-awesome-icon
      class="slider-icon"
      :icon="['fas', 'volume-low']"
    />
    <input class="slider" type="range" min="0" max="1" step="0.01" :value="value">
    <font-awesome-icon
      class="slider-icon"
      :icon="['fas', 'volume-high']"
    />
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true
    }
  }
}
</script>

<style lang="scss">
.slider-container {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
}

.slider {
  -webkit-appearance: none;
  width: 100px;
  height: 12px;
  border-radius: 12px;
  background: grey;
  outline: none;
  opacity: 1;
  -webkit-transition: .2s;
  transition: opacity .2s;
  overflow: hidden;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 50%; 
  background: #fff;
  cursor: pointer;
  box-shadow: -205px 0 0 200px var(--button_color);
}

.slider::-moz-range-thumb {
  box-shadow: -205px 0 0 200px var(--button_color);
  border: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}
</style>