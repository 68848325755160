<template>
  <Teleport to="#app">
    <info-modal
      :show="popupState"
      :is-large="isLarge"
      @close="setPopupState(null)"
    >
      <template #header>
        <header-wrapper />
      </template>
      <template #body>
        <body-wrapper />
      </template>      
    </info-modal>
  </Teleport>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useStore } from '../../store'

import InfoModal from '../generic/InfoModal.vue'
import HeaderWrapper from './headers/HeaderWrapper.vue'
import BodyWrapper from './body/BodyWrapper.vue'

const { POPUP_STATES } = require('../../utils/constants')

export default {
  name: 'PopupWrapper',
  components: {
    InfoModal,
    HeaderWrapper,
    BodyWrapper
  },

  created() {
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.setPopupState(null)
      }
    })
  },

  computed: {
    ...mapState(useStore, ['popupState']),

    isLarge () {
      return this.popupState !== POPUP_STATES.INFO && this.popupState !== POPUP_STATES.SETTINGS
    }
  },

  methods: {
    ...mapActions(useStore, ['setPopupState'])
  }
}
</script>
