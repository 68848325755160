<template>
  <input
    :id="id"
		class="input-switch"
		:class="{'input-switch--has-colors': !disableColors}"
    type="checkbox"
    :checked="checked"
    @input="$emit('input', $event)"
  />
  <label
		class="input-switch__label"
		:for="id"
	/>
</template>

<script>
export default {
  emits: ['input'],
  props: {
    checked: {
      type: Boolean,
      default: false
    },
		disableColors: {
			type: Boolean,
      default: false
		}
  },
  data: () => {
    return {
      id: crypto.randomUUID()
    }
  }
}
</script>

<style lang="scss" scoped>
.input-switch[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
	display: none;
}

.input-switch__label {
	cursor: pointer;
	text-indent: -9999px;
	width: 60px;
	height: 26px;
	background: grey;
	display: block;
	border-radius: 26px;
	position: relative;
}

.input-switch__label:after {
	content: '';
	position: absolute;
	top: 3px;
	left: 3px;
	width: 20px;
	height: 20px;
	background: #fff;
	border-radius: 20px;
	transition: 0.3s;
}

.input-switch--has-colors:checked + .input-switch__label {
	background: var(--button_color);
}

.input-switch:checked + .input-switch__label:after {
	left: calc(100% - 3px);
	transform: translateX(-100%);
}

.input-switch__label:active:after {
	width: 25px;
}
</style>