<template>
  <div class="wrapper">
    <MyPiano
      :is-app-disabled="isAppDisabled"
    />
  </div>
  <div class="footer">
    <div class="footer__social">
      <a class="footer__social-icon-container" target="_blank" href="https://www.linkedin.com/in/carlos-navas-l%C3%B3pez/">
        <img class="footer__social-icon" src="@/assets/linkedin.png" alt="Linkedin" />
      </a>
      <a class="footer__social-icon-container" target="_blank" href="https://twitter.com/pianobasics_web">
        <img class="footer__social-icon" src="@/assets/twitter.png" alt="Twitter" />
      </a>
      <a class="footer__social-icon-container" target="_blank" href="https://linktr.ee/pianobasics">
        <img class="footer__social-icon--linktree" :src="linktreeSrc" alt="Linktree" />
      </a>
    </div>
    <span class="footer__email">{{ $t('contact') }}<a class="footer__email" href="mailto: info@pianobasics.es">info@pianobasics.es</a></span>
  </div>
</template>

<script>
import MyPiano from './components/MyPiano.vue'

import { mapActions, mapState } from 'pinia'
import { useStore } from './store'

const { LIGHT_MODE_KEYS } = require('./utils/constants')

export default {
  name: 'App',
  components: {
    MyPiano
  },
  
  data() {
    return {
      windowWidth: 0,
      isAppDisabled: true
    }
  },

  computed: {
    ...mapState(useStore, ['isDarkModeEnabled']),
    ...mapState(useStore, ['selectedColor']),

    linktreeSrc () {
      return this.isDarkModeEnabled
        ? require('@/assets/linktree.png')
        : require('@/assets/linktree_black.png')
    }
  },

  beforeMount() {
    this.checkWidthAndOrientation()
    const themeKey = this.isDarkModeEnabled
        ? LIGHT_MODE_KEYS.DARK
        : LIGHT_MODE_KEYS.LIGHT
    document.body.setAttribute('theme', `${this.selectedColor}-${themeKey}`)
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    this.setIsMobile(isMobile)
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.checkWidthAndOrientation)
      window.addEventListener('orientationchange', this.checkOrientation)
      window.addEventListener('fullscreenchange', this.checkFullScreen)
    })
  },

  beforeUnmount() { 
    window.removeEventListener('resize', this.checkWidthAndOrientation)
    window.removeEventListener('orientationchange', this.checkOrientation)
  },

  methods: {
    ...mapActions(useStore, ['setScreenSize']),
    ...mapActions(useStore, ['setFullScreen']),
    ...mapActions(useStore, ['setIsMobile']),

    checkWidthAndOrientation() {
      this.windowWidth = window.innerWidth
      this.checkOrientation()
      this.setScreenSize(this.windowWidth)
    },

    checkOrientation() {
      const isPortrait = screen.orientation
        ? screen.orientation.type === 'portrait-primary' || screen.orientation.type === 'portrait-secondary'
        : window.innerWidth < window.innerHeight
      if (isPortrait && screen.availWidth < 600) {
        this.isAppDisabled = true
      } else {
        this.isAppDisabled = false
      }
    },

    checkFullScreen () {
      this.setFullScreen(!!document.fullscreenElement)
    }
  }
}
</script>

<style lang="scss">
html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
}

#app {
  height: 100%;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  -webkit-tap-highlight-color: rgba(255,255,255,0);
  -webkit-tap-highlight-color: transparent;
}

.wrapper{
  flex-grow: 1;
  overflow: auto;
  flex-shrink: 1;
  min-height: 0;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: var(--background_color);
  color: var(--text_color);
}

.footer{
  background-color: var(--background_color);
  color: var(--text_color);
  border-top: 1px solid var(--separator_color);
  min-height: 30px;
  width: 100%;
  padding: 0px 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .footer__social {
    display: flex;
    gap: 20px;
    .footer__social-icon-container{
      display: flex;
      align-items: center;
    }
    .footer__social-icon{
      width: 24px;
      height: 24px;
      &--linktree{
        width: 100px;
        height: 18px;
      }
    }
  }
  .footer__email{
    color: inherit;
    font-size: 13px;
    text-decoration: none;
  }
}
</style>
