<template>
  <div
    :class="`key
      key--${keyObject.type}
      ${keyObject.hasOffset ? 'key--offset' : ''}
      ${selectedNote ? 'key--selected' : ''}
      key--${suffixClassToAdd}
      ${!isMobile ? 'key--is-desktop' : ''}`
    "
  >
    <template v-if="keyObject.type === KEY_TYPES.BLACK && !isMobile">
      <div
        class="key__overlay key__overlay--top"
        @mousedown.prevent="selectNote(keyObject.notes[1])"
        @touchstart.prevent="selectNote(keyObject.notes[1])"
      >
        <span>{{ translateNote(keyObject.notes[1], notation) }}</span>
      </div>
      <div
        class="key__overlay key__overlay--bottom"
        @mousedown.prevent="selectNote(keyObject.notes[0])"
        @touchstart.prevent="selectNote(keyObject.notes[0])"
      >
        <span>{{ translateNote(keyObject.notes[0], notation) }}</span>
      </div>
    </template>
    <select
      v-else-if="keyObject.type === KEY_TYPES.BLACK && isMobile"
      class="key__mobile-select"
      :value="selectedNoteInsideKey"
      @input="selectNote($event.target.value)"
      @mousedown="onClickSelect"
      @touchstart="onClickSelect"
    >
      <option
        :key="keyObject.notes[0]"
        :value="keyObject.notes[0]"
        class="key__mobile-select-option"
      >
        {{ translateNote(keyObject.notes[0], notation) }}
      </option>
      <option
        :key="keyObject.notes[1]"
        :value="keyObject.notes[1]"
        class="key__mobile-select-option"
      >
        {{ translateNote(keyObject.notes[1], notation) }}
      </option>
    </select>
    <div
      v-else
      class="key__overlay-fake"
      @mousedown.prevent="selectNote(keyObject.notes[0])"
      @touchstart.prevent="selectNote(keyObject.notes[0])"
    />
    <span class="key__note" v-if="showNoteSpan">
      {{ translateNote(noteToShow, notation) }}
    </span>
  </div>
</template>

<script>
const { KEY_TYPES, SCREEN_SIZES } = require('../utils/constants')
const { translateNote } = require('../utils/translation-utils')
const { playKey } = require('../utils/sound-utils')

import { mapState, mapActions } from 'pinia'
import { useStore } from '../store'

export default {
  name: 'SingleKey',
  props: {
    keyObject: {
      type: Object,
      required: true
    },
    selectedNote: {
      type: [String, null],
      required: true
    },
    notation: {
      type: String,
      required: true
    },
    showNotesNames: {
      type: Boolean,
      required: true
    },
    keyIndex: {
      type: Number,
      required: true
    },
    octaveNumber: {
      type: Number,
      required: true
    }
  },

  data: () => {
    return {
      selectedNoteInsideKey: null
    }
  },

  computed: {
    ...mapState(useStore, ['isMobile']),
    ...mapState(useStore, ['isPlayModeEnabled']),
    ...mapState(useStore, ['screenSize']),

    showNoteSpan () {
      return this.keyObject.type === KEY_TYPES.BLACK
        ? this.selectedNote && this.showNotesNames
        : this.showNotesNames
    },

    noteToShow () {
      return this.selectedNote || this.keyObject.notes[0]
    },

    suffixClassToAdd () {
      if (this.screenSize === SCREEN_SIZES.LARGE) {
        return 'large'
      } else if (this.screenSize === SCREEN_SIZES.SMALL) {
        return 'small'
      }
      return ''
    }
  },

  beforeMount () {
    this.translateNote = translateNote
    this.KEY_TYPES = KEY_TYPES
  },

  methods: {
    ...mapActions(useStore, ['selectKey']),

    selectNote (note) {
      //alert('Nota: ' + note + ' isTouch: ' + isTouch +' playMode: ' + this.isPlayModeEnabled + ' keyType: ' + this.keyObject.type)
      if (this.isPlayModeEnabled) {
        playKey(this.keyIndex, this.octaveNumber)
      } else {
        this.selectKey({
          keyIndex: this.keyIndex,
          note,
          octaveNumber: this.octaveNumber
        })
      }
      this.$nextTick(() => {
        this.selectedNoteInsideKey = null
      })
    },

    onClickSelect (event) {
      if (this.isPlayModeEnabled) {
        if (event) {
          event.preventDefault()
        }
        playKey(this.keyIndex, this.octaveNumber)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.key {
  position: relative;
  float: left;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  user-select: none;
  cursor: pointer;
}

.key:first-child {
  border-radius: 5px 0 5px 5px;
}

.key:last-child {
  border-radius: 0 5px 5px 5px;
}

.key--offset {
  margin: 0 0 0 $blackKey_offset;
  &.key--small{
    margin: 0 0 0 $blackKey_offset_small;
  }
  &.key--large{
    margin: 0 0 0 $blackKey_offset_large;
  }
}

.key--white {
	height: $whiteKey_height;
	width: $whiteKey_width;
	z-index: 1;
	border-top: $border_width solid $whiteKey_borderColor;
	border-bottom: $border_width solid $whiteKey_borderColor;
	border-left: $border_width solid $whiteKey_borderColor;
  border-radius:0 0 5px 5px;
  box-shadow:-1px 0 0 rgba(255,255,255,0.8) inset,0 0 5px #ccc inset,0 0 3px rgba(0,0,0,0.2);
  background:linear-gradient(to bottom,#eee 0%,#fff 100%);
  font-size: 14px;
  padding-bottom: 5px;
  color: rgba(0, 0, 0, 0.5);
  &.key--small{
    width: $whiteKey_width_small;
  }
  &.key--large{
    width: $whiteKey_width_large;
    height: $whiteKey_height_large;
    border-top: $border_width_large solid $whiteKey_borderColor;
    border-bottom: $border_width_large solid $whiteKey_borderColor;
    border-left: $border_width_large solid $whiteKey_borderColor;
  }
}

.key--white:active {
  border-left: $border_width solid #999;
  border-bottom: $border_width solid #999;
  box-shadow:2px 0 3px rgba(0,0,0,0.1) inset,-5px 5px 20px rgba(0,0,0,0.2) inset,0 0 3px rgba(0,0,0,0.2);
  background:linear-gradient(to bottom,#fff 0%,#e9e9e9 100%);
  outline: none;
  &.key--large{
    border-left: $border_width_large solid #999;
    border-bottom: $border_width_large solid #999;
  }
}

.key--black {
	height: $blackKey_height;
	width: $blackKey_width;
	z-index: 2;
	border: $border_width solid $blackKey_borderColor;
  border-radius: 0 0 5px 5px;
  box-shadow: -1px -1px 2px rgba(255,255,255,0.2) inset,0 -5px 2px 3px rgba(0,0,0,0.6) inset,0 2px 4px rgba(0,0,0,0.5);
  background: linear-gradient(45deg,#222 0%,#555 100%);
  z-index: 2;
  border-radius:0 0 5px 5px;
  color: transparent;
  font-size: 14px;
  &.key--small{
    width: $blackKey_width_small;
  }
  &.key--large{
    width: $blackKey_width_large;
    height: $blackKey_height_large;
    border: $border_width_large solid $blackKey_borderColor;
  }
  &.key--is-desktop:hover{
    background: var(--selector_color);
  }
}

.key--black:active {
  box-shadow:-1px -1px 2px rgba(255,255,255,0.2) inset,0 -2px 2px 3px rgba(0,0,0,0.6) inset,0 1px 2px rgba(0,0,0,0.5);
  background: linear-gradient(to right,#444 0%,#222 100%);
  outline: none;
}

.key--black span {
  color: white;
  margin-bottom: 7px;
}

.key--selected {
  background: var(--selector_color);
  &.key--white{
    background: var(--selector_color);
    border-bottom: $border_width solid;
    border-left: $border_width solid;
    border-color: var(--selector_color);
    .key__note {
      color: white;
    }
    &.key--large{
      border-bottom: $border_width_large solid;
      border-left: $border_width_large solid;
      border-color: var(--selector_color);
    }
    &:active {
      background: var(--selector_color__pressed);
    }
  }
  &.key--black:hover{
    background: var(--selector_color__hover);
    &:active {
      background: var(--selector_color__pressed);
    }
  }
}

.key:hover .key__overlay{
  opacity: 1;
  justify-content: flex-end;
  flex-direction: column;
}

.key--black.key--small.key--selected .key__note{
  display: none;
}

.key--black:hover .key__note{
  display: none;
}

.key__overlay{
  display: flex;
  color: black;
  opacity: 0;
  height: 50%;
  position: absolute;
  width: 100%;
  z-index: 10;
}

.key__overlay--top{
  top: 0;
  border-bottom: 1px solid black;
}

.key__overlay--bottom{
  border-radius: 0 0 5px 5px;
}

.key__overlay:hover{
  span {
    color: white;
  }
}

.key--small .key__overlay:hover{
  background-color: inherit;
  span{
    color: inherit;
  }
}

.key--small.key--selected .key__overlay:hover{
  background-color: rgba(0,0,0,0);
  span{
    color: inherit;
  }
}

.key__overlay-fake {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 10;
  top: 0;
  opacity: 0;
}

.key__mobile-select {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}
</style>
