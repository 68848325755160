<template>
  <div
    v-if="isAppDisabled"
    class="disabled-overlay"
  >
    <img class="disabled-overlay__logo" src="@/assets/negro-transparente.png" alt="Piano basics logo" />
    <div class="disabled-overlay__text">{{ $t('portraitModeDisabled') }}</div>
  </div>
  <template v-else>
    <popup-wrapper />
    <div
      class="my-piano__settings-button"
      @click="setPopupState(POPUP_STATES.SETTINGS)"
    >
      <font-awesome-icon :icon="['fas', 'gear']" />
    </div>
    <div
      v-if="isFullScreenAvailable"
      class="my-piano__settings-button my-piano__settings-button--fullscreen"
      @click="toggleFullScreen()"
    >
      <font-awesome-icon :icon="fullScreenIcon" />
    </div>
    <img
      class="my-piano__logo"
      :src="logoSrc"
      alt="Piano basics logo"
    />
    <span class="my-piano__subtitle">
      <h1>{{ $t('help1') }}</h1>
      <span>{{ $t('help2') }}</span>
      <span class="my-piano__subtitle-icon">
        <span
          class="mode-section__question-button"
          @click="setPopupState(POPUP_STATES.INTRODUCTION)"
        >
          <font-awesome-icon
            :icon="['fas', 'question']"
          />
        </span>
      </span>
    </span>
    <div
      class="my-piano__sections-container"
      :class="{
        'my-piano__sections-container--large': screenSize === SCREEN_SIZES.LARGE
      }"
    >
      <sections-wrapper />
    </div>
    <div
      v-show="showStaff"
      id="staves-container"
      class="my-piano__staves-container"
    >
      <font-awesome-icon
        class="my-piano__staves-container__close-button"
        :icon="['fas', 'xmark']"
        @click="changeShowStaff"
      />
    </div>
    <div
      class="my-piano"
      :class="{
        'my-piano--small': screenSize === SCREEN_SIZES.SMALL
      }"
    >
      <div class="my-piano__settings-container">
        <div class="my-piano__settings-container__mode">
          <span>{{ $t('select') }}</span>
          <input-switch
            :checked="isPlayModeEnabled"
            disable-colors
            @input="changePlayMode"
          />
          <span>{{ $t('play') }}</span>
        </div>
      </div>
      <div class="my-piano__keys-container">
        <template
          v-for="octaveNumber in octavesToShow"
          :key="'octave-' + octaveNumber"
        >
          <singleKey
            v-for="(singleKey, index) in OCTAVE_KEYS"
            :key="index + '-' + octaveNumber"
            :key-object="singleKey"
            :octave-number="octaveNumber"
            :selectedNote="isKeySelected(singleKey.notes, octaveNumber)"
            :notation="selectedNotation"
            :show-notes-names="showNotesNames"
            :key-index="index"
          />
        </template>
      </div>
    </div>
    <info-section v-if="selectedMode"/>
  </template>
</template>

<script>
import SingleKey from './SingleKey.vue'
import SectionsWrapper from './sections/SectionsWrapper.vue'
import InfoSection from './sections/InfoSection.vue'
import PopupWrapper from './popups/PopupWrapper.vue'
import InputSwitch from '@/components/generic/InputSwitch.vue'

import { mapState, mapActions } from 'pinia'
import { useStore } from '../store'

const _ = require('lodash')

const { OCTAVE_KEYS, SCREEN_SIZES, POPUP_STATES } = require('../utils/constants')
const { loadAudios } = require('../utils/sound-utils')
const { initStaves } = require('../utils/stave-utils')

export default {
  name: 'MyPiano',
  components: {
    SectionsWrapper,
    SingleKey,
    InfoSection,
    PopupWrapper,
    InputSwitch
  },

  props: {
    isAppDisabled: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    ...mapState(useStore, ['octavesToShow']),
    ...mapState(useStore, ['selectedMode']),
    ...mapState(useStore, ['selectedNotation']),
    ...mapState(useStore, ['selectedNotesAsObjects']),
    ...mapState(useStore, ['showNotesNames']),
    ...mapState(useStore, ['screenSize']),
    ...mapState(useStore, ['isFullScreen']),
    ...mapState(useStore, ['isDarkModeEnabled']),
    ...mapState(useStore, ['isPlayModeEnabled']),
    ...mapState(useStore, ['showStaff']),

    logoSrc () {
      return this.isDarkModeEnabled
        ? require('@/assets/logo-pianobasics-full-hor-blanco.png')
        : require('@/assets/logo-pianobasics-full-hor.png')
    },

    fullScreenIcon () {
      return this.isFullScreen
        ? ['fas', 'compress']
        : ['fas', 'expand']
    }
  },

  beforeMount () {
    this.SCREEN_SIZES = SCREEN_SIZES
    this.OCTAVE_KEYS = OCTAVE_KEYS
    this.POPUP_STATES = POPUP_STATES
    this.isFullScreenAvailable = !!document.fullscreenEnabled
    loadAudios()
  },

  mounted () {
    initStaves()
  },

  methods: {
    ...mapActions(useStore, ['selectInterval']),
    ...mapActions(useStore, ['changePlayMode']), 
    ...mapActions(useStore, ['changeSelectedNotation']),
    ...mapActions(useStore, ['changeShowNotesNames']),
    ...mapActions(useStore, ['setPopupState']),
    ...mapActions(useStore, ['toggleFullScreen']),
    ...mapActions(useStore, ['changeShowStaff']),

    isKeySelected (keyNotes, octaveNumber) {
      if(!this.selectedNotesAsObjects.length) return null

      const keyObject = _.find(this.selectedNotesAsObjects, note => octaveNumber === note.octaveNumber && keyNotes.indexOf(note.note) >= 0)
      return _.get(keyObject, 'note', null)
    }
  }
}
</script>

<style lang="scss">
.my-piano {
  position: relative;
  border: 1px solid black;
  border-radius: 1rem;
  background-color: var(--main_color);
  display: inline-flex;
  margin-top: 20px;
  margin-bottom: 15px;
  padding: 0px 40px;
  flex-direction: column;
  justify-content: space-between;
  box-shadow:0 0 10px rgba(0,0,0,0.4) inset,0 1px rgba(212,152,125,0.15) inset,0 5px 15px rgba(0,0,0,0.4);
  &.my-piano--small{
    margin-top: 10px;
    padding: 0px 20px;
  }
}

.my-piano__settings-button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--button_color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 25px;
  right: 25px;
  color: white;
  &--fullscreen{
    top: 70px;
  }
  &:hover {
    background-color: var(--button_color__hover);
  }
  &:active {
    background-color: var(--button_color__pressed);
  }
}

.my-piano__logo {
  width: 300px;
  height: 50px;
  margin-top: 15px;
}

.my-piano__subtitle {
  margin: 20px 0;
  line-height: 24px;
  width: 1000px;
  max-width: 90%;
  h1 {
    display: inline;
    font-size: 16px;
    font-weight: normal;
  }
}

.my-piano__subtitle-icon {
  display: inline-block;
  margin-left: 5px;
}

.my-piano__settings-container {
  display: flex;
  padding: 12px 0;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.my-piano__settings-container__mode {
  display: flex;
  gap: 15px;
  align-items: center;
  width: 400px;
  span {
    color: white;
    flex: 1 1 0px;
    text-align: left;
  }
  span:nth-of-type(1) {
    text-align: right;
  }
  .input-switch__label {
    width: 48px;
    height: 18px;
    &:after{
      width: 14px;
      height: 14px;
      top: 2px;
    }
  }
}

.my-piano--small .my-piano__settings-container{
  padding: 10px 0;
}

.my-piano__setting-container-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 12px;
  width: 175px;
}

.my-piano__setting-container {
  align-items: center;
  display: flex;
  height: 25px;
  gap: 5px;
  
  &:last-child{
    margin-right: 0px;
  }
}

.my-piano__keys-container {
  margin: 0 auto;
}

.my-piano__sections-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: $keyboard_width;
  &.my-piano__sections-container--large {
    max-width: $keyboard_width_large;
  }
}

.my-piano__staves-container {
  background-color: white;
  padding: 0 10px;
  margin-top: 20px;
  border-radius: 6px;
  position: relative;
}

.my-piano__staves-container__close-button {
  position: absolute;
  top: 5px;
  right: 6px;
  width: 15px;
  height: 20px;
  cursor: pointer;
}

.disabled-overlay {
  margin: 0px 20px;
  font-size: 24px;
}

.disabled-overlay__logo{
  width: 80%;
  margin-bottom: 20px;
}
</style>
