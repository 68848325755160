<template>
  <h3>{{ headerTitle }}</h3>
</template>

<script>
import { mapState } from 'pinia'
import { useStore } from '../../../store'

const { POPUP_STATES } = require('../../../utils/constants')

export default {
  name: 'HeaderWrapper',
  computed: {
    ...mapState(useStore, ['popupState']),

    headerTitle () {
      switch (this.popupState) {
        case POPUP_STATES.INFO:
          return this.$t('popupContent.help.title')
        case POPUP_STATES.INTRODUCTION:
          return this.$t('popupContent.introduction.title')
        case POPUP_STATES.INTERVAL:
          return this.$t('popupContent.intervals.title')
        case POPUP_STATES.INVERT_INTERVAL:
          return this.$t('popupContent.invertedIntervals.title')
        case POPUP_STATES.CHORD:
          return this.$t('popupContent.chords.title')
        case POPUP_STATES.CHORD_STATE:
          return this.$t('popupContent.chordStates.title')
        case POPUP_STATES.SCALE:
          return this.$t('popupContent.scales.title')
        case POPUP_STATES.GREEK_MODE:
          return this.$t('popupContent.greekModes.title')
        case POPUP_STATES.SETTINGS:
          return this.$t('popupContent.settings.title')
        default: 
          return ''
      }
    }
  }
}
</script>
