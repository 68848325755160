<template>
  <div class="color-picker">
    <div
      v-for="color in COLORS"
      :key="color"
      class="color-picker__pill"
      :class="[
        `color-picker__pill--${color}`,
        {
          'color-picker__pill--active': colorMap[color]
        }
      ]"

      @click="click(color)"
    />
  </div>
</template>
<script>
import { mapState } from 'pinia'
import { useStore } from '../../store'

const { COLORS } = require('../../utils/constants')

export default {
  computed: {
    ...mapState(useStore, ['selectedColor'])
  },
  data: () => {
    return {
      colorMap: {
        [COLORS.GREEN]: false,
        [COLORS.BLUE]: false,
        [COLORS.RED]: false
      }
    }
  },
  beforeMount() {
    this.COLORS = COLORS
    this.colorMap[this.selectedColor] = true
  },
  methods: {
    click (color) {
      this.colorMap[COLORS.GREEN] = false
      this.colorMap[COLORS.BLUE] = false
      this.colorMap[COLORS.RED] = false
      this.colorMap[color] = true
      this.$emit('select-color', color)
    }
  }
}
</script>

<style lang="scss" scoped>
.color-picker {
  display: flex;
  gap: 12px;
  width: 150px;
  height: 40px;
  align-items: center;
}

.color-picker__pill {
  height: 30px;
  border-radius: 5px;
  flex: 1;
  cursor: pointer;
  border: 2px solid transparent;
}

[theme="green-dark"], [theme="blue-dark"], [theme="red-dark"] {
  .color-picker__pill--active{
    border-color: white;
  }
  .color-picker__pill--green{
    background-color: $button_color__green__dark;
    &:hover {
      background-color: $button_color__hover__green__dark;
    }
  }
  .color-picker__pill--blue{
    background-color: $button_color__blue__dark;
    &:hover {
      background-color: $button_color__hover__blue__dark;
    }
  }
  .color-picker__pill--red{
    background-color: $button_color__red__dark;
    &:hover {
      background-color: $button_color__hover__red__dark;
    }
  }
} 

[theme="green-light"], [theme="blue-light"], [theme="red-light"]{
  .color-picker__pill--active{
    border-color: dimgray;
  }
  .color-picker__pill--green{
    background-color: $button_color__green__light;
    &:hover {
      background-color: $button_color__hover__green__light;
    }
  }
  .color-picker__pill--blue{
    background-color: $button_color__blue__light;
    &:hover {
      background-color: $button_color__hover__blue__light;
    }
  }
  .color-picker__pill--red{
    background-color: $button_color__red__light;
    &:hover {
      background-color: $button_color__hover__red__light;
    }
  }
}
</style>