<template>
  <mode-section />
  <template v-if="areMoreOptionsDispayed && hasMoreOptions">
    <template v-if="selectedMode === MODE_NAMES.INTERVAL">
      <interval-section />
    </template>
    <template v-else-if="selectedMode === MODE_NAMES.CHORD">
      <chord-section />
    </template>
  </template>
</template>

<script>
import { mapState } from 'pinia'
import { useStore } from '../../store'

import ModeSection from './ModeSection.vue'
import IntervalSection from './IntervalSection.vue'
import ChordSection from './ChordSection.vue'

const { MODE_NAMES, HAS_MORE_OPTIONS_BY_MODE } = require('../../utils/constants')

export default {
  name: 'SectionsWrapper',
  components: {
    ModeSection,
    IntervalSection,
    ChordSection
  },

  beforeMount () {
    this.MODE_NAMES = MODE_NAMES
  },

  computed: {
    ...mapState(useStore, ['selectedKey']),
    ...mapState(useStore, ['selectedMode']),
    ...mapState(useStore, ['areMoreOptionsDispayed']),

    hasMoreOptions () {
      return !!HAS_MORE_OPTIONS_BY_MODE[this.selectedMode]
    }
  }
}
</script>
