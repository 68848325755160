const {
  MAIN_CHORDS_NAMES,
  SEVENTH_NAMES,
  CHORD_STATES_NAMES,
  INTERVAL_NAMES,
  INTERVAL_TYPES,
  MODE_NAMES,
  SCALES_NAMES,
  GREEK_MODES_NAMES
} = require('../utils/constants')

module.exports = {
  showNoteNames: 'Mostrar notas',
  selectChord: 'Acorde',
  seventh: 'Séptima',
  chordState: 'Estado',
  mainChords: {
    [MAIN_CHORDS_NAMES.MINOR_TRIAD]: 'Triada menor',
    [MAIN_CHORDS_NAMES.MAJOR_TRIAD]: 'Triada mayor',
  },
  fourNoteChords: {
    [MAIN_CHORDS_NAMES.MINOR_TRIAD]: 'Cuatriada menor',
    [MAIN_CHORDS_NAMES.MAJOR_TRIAD]: 'Cuatriada mayor',
  },
  fourNoteChordsComplete: {
    [SEVENTH_NAMES.MINOR_SEVENTH]: '{chordName} con séptima menor',
    [SEVENTH_NAMES.MAJOR_SEVENTH]: '{chordName} con séptima mayor',
    //[SEVENTH_NAMES.DIMINISHED_SEVENTH]: '{chordName} con séptima disminuida',
  },
  seventhNames: {
    [SEVENTH_NAMES.NO_SEVENTH]: 'Sin séptima',
    [SEVENTH_NAMES.MINOR_SEVENTH]: 'Séptima menor',
    [SEVENTH_NAMES.MAJOR_SEVENTH]: 'Séptima mayor',
    //[SEVENTH_NAMES.DIMINISHED_SEVENTH]: 'Séptima disminuida',
  },
  chordStates: {
    [CHORD_STATES_NAMES.NATURAL_STATE]: 'Estado fundamental',
    [CHORD_STATES_NAMES.FIRST_INVERSION]: 'Primera inversión',
    [CHORD_STATES_NAMES.SECOND_INVERSION]: 'Segunda inversión',
    [CHORD_STATES_NAMES.THIRD_INVERSION]: 'Tercera inversión',
  },
  notesOutWarning: 'Cuidado! Hay notas fuera del teclado y no se pueden mostrar',
  select: 'Seleccionar',
  play: 'Escuchar',
  playArpeggiated: 'Escuchar arpegiado',
  interval: 'Intervalo',
  intervalNames: {
    [INTERVAL_NAMES.MINOR_SECOND]: 'Segunda menor',
    [INTERVAL_NAMES.MAJOR_SECOND]: 'Segunda mayor',
    [INTERVAL_NAMES.AUGMENTED_SECOND]: 'Segunda aumentada',
    [INTERVAL_NAMES.MINOR_THIRD]: 'Tercera menor',
    [INTERVAL_NAMES.MAJOR_THIRD]: 'Tercera mayor',
    [INTERVAL_NAMES.PERFECT_FOURTH]: 'Cuarta justa',
    [INTERVAL_NAMES.AUGMENTED_FOURTH]: 'Cuarta aumentada',
    [INTERVAL_NAMES.DIMINISHED_FIFTH]: 'Quinta disminuida',
    [INTERVAL_NAMES.PERFECT_FIFTH]: 'Quinta justa',
    [INTERVAL_NAMES.MINOR_SIXTH]: 'Sexta menor',
    [INTERVAL_NAMES.MAJOR_SIXTH]: 'Sexta mayor',
    [INTERVAL_NAMES.DIMINISHED_SEVENTH]: 'Séptima disminuida',
    [INTERVAL_NAMES.MINOR_SEVENTH]: 'Séptima menor',
    [INTERVAL_NAMES.MAJOR_SEVENTH]: 'Séptima mayor',
    [INTERVAL_NAMES.PERFECT_OCTAVE]: 'Octava justa'
  },
  intervalOrder: 'Orden',
  intervalTypes: {
    [INTERVAL_TYPES.DOWNWARD]: 'Descendente',
    [INTERVAL_TYPES.UPWARD]: 'Ascendente',
  },
  invertInterval: 'Invertir intervalo',
  selectMode: 'Modo de uso',
  modeNames: {
    [MODE_NAMES.INTERVAL]: 'Intervalo',
    [MODE_NAMES.CHORD]: 'Acorde',
    [MODE_NAMES.SCALE]: 'Escala',
    [MODE_NAMES.GREEK_MODE]: 'Modo griego',
  },
  scale: 'Escala',
  scaleNames: {
    [SCALES_NAMES.MAJOR]: 'Mayor',
    [SCALES_NAMES.NATURAL_MINOR]: 'Menor natural',
    [SCALES_NAMES.HARMONIC_MINOR]: 'Menor armónica',
    [SCALES_NAMES.MELODIC_MINOR]: 'Menor melódica',
    [SCALES_NAMES.PENTATONIC_MAJOR]: 'Pentatónica mayor',
    [SCALES_NAMES.PENTATONIC_MINOR]: 'Pentatónica menor',
    [SCALES_NAMES.BLUES_SCALE]: 'Escala de blues'
  },
  greekMode: 'Modo griego',
  greekModeNames: {
    [GREEK_MODES_NAMES.IONIAN]: 'Jónico',
    [GREEK_MODES_NAMES.DORIAN]: 'Dórico',
    [GREEK_MODES_NAMES.PHRYGIAN]: 'Frigio',
    [GREEK_MODES_NAMES.LYDIAN]: 'Lidio',
    [GREEK_MODES_NAMES.MIXOLYDIAN]: 'Mixolidio',
    [GREEK_MODES_NAMES.AEOLIAN]: 'Eolio',
    [GREEK_MODES_NAMES.LOCRIAN]: 'Locrio'
  },
  invertedInterval: 'Intervalo invertido',
  tones: '{tones} tono | {tones} tonos',
  semitones: '{semitones} semitono | {semitones} semitonos',
  and: ' y ',
  intervalInfo: '{intervalTitle}: {interval} ({tones}) {intervalType} de {note}, formado por las notas: {notes}',
  chordInfo: '{chordTitle}: {chord} de {note} en {chordState}, formado por las notas: {notes}',
  scaleInfo: '{scaleTitle} {scale} de {note}, formado por las notas: {notes}',
  volume: 'Volumen',
  portraitModeDisabled: 'El modo vertical está desactivado para este dispositivo, por favor cambia a modo horizontal para poder usar la app.',
  freeModeWarning: 'Mientras "Escuchar" esté activado, no podrás seleccionar ningún intervalo, acorde o escala pulsando en el teclado.',
  help1: 'Piano Basics es una web que sirve de apoyo a estudiantes de música en el aprendizaje de intervalos, acordes y escalas. ',
  help2: 'Si quieres ver una introdución a la teoría musical, pulsa en la interrogación',
  contact: 'Contacto: ',
  moreOptions: 'Más opciones',
  useAmericanNotation: 'Usar cifrado americano',
  showStaff: 'Mostrar partitura',
  useDarkMode: 'Usar modo oscuro',
  popupContent: {
    help: {
      title: 'Bienvenido a PianoBasics',
      content: `<p>Piano Basics es una web que sirve de apoyo a estudiantes de música en el aprendizaje de intervalos, acordes y escalas.</p>
      <p>Usando el piano y los selectores, podrás formar y escuchar las combinaciones de intervalos, acordes o escalas que necesites practicar. En cada uno de los botones de ayuda tienes disponible un resumen de la teoría sobre el modo correspondiente</p>
      <p>Para cualquier sugerencia no dudes en contactar por cualquiera de los medios que aparecen en la parte inferior de la página.</p>`
    },
    introduction: {
      title: 'Introducción',
      content: `<p>La teoría musical es el estudio de los diferentes elementos de la música. PianoBasics nos ayudará a entender cómo se organizan los distintos sonidos, las notas y cómo se forman intervalos, acordes o escalas, ayudándonos del piano como elemento visual.</p>
      <h3>Notas</h3>
      <p>Toda la música está compuesta por notas, ya que es el elemento básico fundamental en el que se basa la música. Una nota es el nombre que le damos a un sonido específico (una frecuencia definida). Las notas musicales son siete: Do, Re, Mi, Fa, Sol, La Si y Do (en notación americana: C, D, E, F, G, A, B y C en el mismo orden).</p>
      <div>Las notas anteriores se llaman <strong>notas naturales</strong> y están representadas en un piano por las teclas blancas. Es posible subir o bajar semitonos a estas notas para formar <strong>notas alteradas:</strong>
      <ul>
        <li>si subimos semitonos hablamos de sostenidos</li>
        <li>si bajamos semitonos hablamos de bemoles</li>
        <li>también existen dobles sostenidos y dobles bemoles, pero son menos comunes</li>
      </ul>
      </div>
      <p>Si activamos en ajustes la opción de “mostrar notas” podréis verlas representadas en el piano.</p>
      <p>¿Cómo se representan las notas en un piano?. El piano se estructura en octavas, siendo una octava todas las teclas que hay entre un Do y el siguiente Do ascendente o descendente.</p>
      <h3>Distancia tonal</h3>
      <p>La <strong>distancia tonal</strong> se mide en semitonos y/o tonos (1 tono equivale a 2 semitonos, siendo un semitono la distancia mínima entre dos notas). Calcular la distancia en semitonos en piano es muy simple, ya que entre cada una de las teclas hay 1 semitono de distancia (incluyendo las teclas negras). Por ejemplo, entre Do y Mi en orden ascendente hay 4 semitonos (2 tonos), ya que hay 4 desplazamientos entre las dos notas (De Do a Do#, de Do# a Re, de Re a Re# y de Re# a Mi).</p>
      <p>Con esta pequeña introducción acerca de lo que son las notas, como se representan en un piano y la distancia tonal, estamos listos para poder usar PianoBasics y aprender sobre intervalos, acordes y escalas.</p>
      `,
    },
    intervals: {
      title: 'Intervalos musicales',
      content: `<p>Un intervalo es la diferencia de altura entre dos notas. Para definirlos necesitamos usar tres conceptos: la altura, la distancia tonal y su orden.</p>
      <p>La <strong>altura</strong> se mide contando cuántas <strong>notas naturales</strong> existen entre las dos notas que definen el intervalo (incluyendo las dos notas).</p>
      <p>Por ejemplo, entre Do y Fa en orden ascendente tenemos cuatro notas naturales de altura: Do, Re, Mi y Fa. Otro ejemplo sería Do y Fa# en orden ascendente, en el que existen también cuatro notas naturales de altura: Do, Re, Mi y Fa (no nos importa el sostenido de Fa ya que utilizamos notas naturales). La altura siempre será indicada en femenino (en el caso de Do y Fa, sería una cuarta).</p>
      <p>La <strong>especie</strong> del intervalo será definida partiendo de la distancia tonal, es decir, la cantidad de tonos y semitonos que hay entre las dos notas. Los tipos de especie son: mayor, menor, justa, aumentada o disminuida. La especie de un intervalo también deberá ser indicada en femenino, al igual que la altura.</p>
      <p>El <strong>orden</strong> de un intervalo puede ser ascendente o descendente. Tomando una nota como referencia, si el intervalo es ascendente se desplaza hacia notas más agudas y si es descendente hacia notas más graves.</p>
      <p>En la siguiente tabla puedes ver los principales intervalos musicales con su distancia tonal (en tonos y semitonos) y su altura, si lo deseas puedes descargarla pinchando en la imagen.</p>
      `,
      tableName: 'interval_table_es.jpg'
    },
    invertedIntervals: {
      title: 'Inversión de intervalos',
      content: `<p>Invertir un intervalo consiste en ascender una octava la nota más grave de éste, cambiando la altura, la distancia tonal y la especie del intervalo.</p>
      <p>El intervalo de octava justo es el único que mantiene su distancia tonal, altura y especie al invertirlo.</p>
      <p>Para el resto de intervalos, al invertirlo siempre se cumple:</p>
      <ul>
        <li>La altura del intervalo más la del intervalo invertido siempre es nueve (por ejemplo, una inversión de un intervalo de segunda siempre será una séptima)</li>
        <li>En cuanto a la especie, al invertir un intervalo:
          <ul>
            <li>mayor se convierte en menor</li>
            <li>menor se convierte en mayor</li>
            <li>aumentado se convierte en disminuido</li>
            <li>disminuido se convierte en aumentado</li>
            <li>justo se mantiene justo</li>
          </ul>
        </li>
      </ul>
      <p>En la siguiente tabla puedes ver los principales intervalos musicales con su inversión. Si lo deseas puedes descargarla pinchando en la imagen.</p>
      `,
      tableName: 'inverted_interval_table_es.jpg'
    },
    chords: {
      title: 'Acordes',
      content: `<p>Un acorde es la combinación de dos o más notas distintas. Es importante saber la teoría de intervalos ya que los acordes se construyen a partir de una <strong>nota fundamental</strong> (nota que define el acorde) y dos o más intervalos a partir de ella. Los acordes siempre se construirán en orden ascendente.</p>
      <p>Para empezar, vamos a centrarnos en los acordes de tres notas, llamados <strong>acordes triada</strong>. En concreto la triada mayor y la triada menor.</p>
      <h3>Triada mayor</h3>
      <p>Un acorde <strong>triada mayor</strong> (acorde mayor) está compuesto por su nota fundamental, un intervalo de tercera <strong>mayor</strong> y un intervalo de quinta justa (ambos ascendentes) a partir de la nota fundamental. Ejemplo de cómo construir un acorde mayor:</p>
      <ol>
        <li>Elegimos una nota fundamental, por ejemplo Do</li>
        <li>Calculamos el intervalo de tercera mayor ascendente de Do: Mi</li>
        <li>Calculamos el intervalo de quinta justa ascendente desde Do: Sol</li>
      </ol>
      <p>Por lo tanto el acorde tríada mayor de Do está formado por las notas Do, Mi y Sol.</p>
      <h3>Triada menor</h3>
      <p>Un acorde <strong>triada menor</strong> (acorde menor) se define por su nota fundamental, un intervalo de tercera <strong>menor</strong> y un intervalo de quinta justa (ambos ascendentes) de la nota fundamental. Ejemplo de cómo construir un acorde menor:</p>
      <ol>
        <li>Elegimos una nota fundamental, por ejemplo Do</li>
        <li>Calculamos el intervalo de tercera menor ascendente de Do: Mi bemol</li>
        <li>Calculamos el intervalo de quinta justa ascendente desde Do: Sol</li>
      </ol>
      <p>Por lo tanto el acorde triada menor de Do está formado por las notas Do, Mi bemol y Sol.</p>
      <p>Como podemos ver, el único cambio entre una triada mayor y otra menor es el intervalo de tercera utilizado para formar el acorde.</p>`
    },
    chordStates: {
      title: 'Estado del acorde',
      content: `<p>El estado de un acorde define la disposición de las notas que lo componen. En el caso de los acordes triada, al estar formado por tres notas podemos representarlos de tres maneras: estado fundamental, primera inversión y segunda inversión.
      A continuación, se harán las representaciones de estos tres estados tomando de ejemplo un acorde triada de Do Mayor en posición cerrada, es decir, tocando las notas del acorde notas más próximas entre sí.</p>
      <h3>Estado fundamental</h3>
      <p>En el estado fundamental, la nota fundamental será la más grave del acorde (es decir, como lo hemos representado en el apartado anterior)</p>
      <img class="popup-body__chord_state_img" src={rootStateImg} alt="Root state chord"></img>
      <h3>Primera inversión</h3>
      <p>La primera inversión desplaza la nota fundamental una octava en orden ascendente, por lo que la segunda nota del acorde (intervalo de tercera) es la nota más grave del acorde. En un acorde Do mayor (Do, Mi y Sol) en primera inversión, la nota más grave sería el Mi, ya que hemos desplazado el Do.</p>
      <img class="popup-body__chord_state_img" src={firstInversionImg} alt="First inversion chord"></img>
      <h3>Segunda inversión</h3>
      <p>La segunda inversión desplaza las dos primeras notas del acorde (nota fundamental y segunda nota del acorde) una octava en orden ascendente, por lo que la tercera nota del acorde (intervalo de quinta) es la nota más grave del acorde. En un acorde Do mayor (Do, Mi y Sol) en segunda inversión, la nota más grave sería el Sol, ya que hemos desplazado el Do y el Mi.</p>
      <img class="popup-body__chord_state_img" src={secondInversionImg} alt="Second inversion chord"></img>
      `,
      rootStateImg: 'root_position_es.png',
      firstInversionImg: 'first_inversion_es.png',
      secondInversionImg: 'second_inversion_es.png'
    },
    scales: {
      title: 'Escalas',
      content: `<p>Una escala musical es una sucesión de notas que sigue un esquema. La nota de partida la definiremos como fundamental.</p>
      <p><strong>Las escalas se construyen pensando siempre en orden ascendente.</strong></p>
      <h3>Escalas principales</h3>
      <p>Las escalas más conocidas son las escalas mayores y menores. Las normas para construir estas escalas son:</p>
      <ul>
        <li>La escala tiene que ir de octava a octava (por ejemplo, si la nota fundamental es Do, la escala terminaría en el Do ascendente). La nota fundamental le daría nombre a la escala, por ejemplo: escala de Do Mayor.</li>
        <li>Estas escalas están compuestas de 8 notas. La única nota repetida es la nota fundamental (en este caso, el Do).</li>
        <li>El resto de notas deben aparecer en su totalidad, en orden sucesivo ascendente (en el caso de la escala de Do Mayor, Do Re Mi Fa Sol La Si Do). Estas notas no deben repetirse. Por ejemplo, en una escala de Do Mayor, no deben aparecer un Mi y un Mi#. El Mi# obtendría la denominación de Fa, y así sucesivamente.</li>
        <img class="popup-body__chord_state_img" src={naturalNotesImg} alt="Natural notes scale"></img>
        <li>En caso de que las notas naturales no guardaran coherencia con el esquema, se podrían alterar. Por ejemplo: en la escala de Sol Mayor, para que el esquema sea T-T-ST-T-T-T-ST, el Fa tendría que ser alterado un semitono ascendente.
        La séptima nota no podría ser Sol bemol, ya que la norma anterior indica que deben aparecer todas las notas entre Sol y Sol en orden sucesivo ascendente. Por lo tanto, las notas de la escala de Sol Mayor deberían ser: Sol La Si Do Re Mi <strong>Fa#</strong> Sol.</li>
        <img class="popup-body__chord_state_img" src={alteredNotesImg} alt="Altered notes scale"></img>
      </ul>
      <p>Para definir el esquema de estas escalas utilizaremos la distancia tonal.</p>
      <ul>
        <li><strong>Escala mayor:</strong> (T-T-ST-T-T-T-ST)</li>
        <li><strong>Escala menor natural:</strong> (T-ST-T-T-ST-T-T)</li>
        <li><strong>Escala menor armónica:</strong> escala menor natural, pero la 7ª respecto a la fundamental ascendería un semitono, convirtiéndose en una 7ª Mayor.</li>
        <li><strong>Escala menor melódica:</strong> escala menor natural, pero la 6ª y 7ª respecto a la fundamental serían intervalos mayores, ascendiendo un semitono respecto a la escala menor natural.</li>
      </ul>
      <h3>Otras escalas</h3>
      <p>Hay otras escalas incluidas en esta versión de PianoBasics que no cumplen las normas de la escala mayor y las escalas menores, que son las siguientes:</p>
      <ul>
        <li><strong>Escala pentatónica mayor:</strong> similar a la escala Mayor, pero omitiendo la cuarta y séptima nota de la escala. Por ejemplo, la escala pentatónica mayor de Do tendría las siguientes notas: Do Re Mi Sol La Do.</li>
        <li><strong>Escala pentatónica menor:</strong> similar a la escala menor, pero omitiendo la segunda y sexta nota de la escala. Por ejemplo, la escala pentatónica menor de Do tendría las siguientes notas: Do Mib Fa Sol Sib Do.</li>
        <li><strong>Escala de blues:</strong> similar a la escala pentatónica menor, pero añadiendo una cuarta aumentada (4ªA) respecto a la nota fundamental entre la tercera y cuarta nota. Esta es la primera escala que nos encontramos en la que hay dos notas repetidas. En el caso de la escala de blues de Do, nos encontraríamos las siguientes notas: Do Mib Fa <strong>Fa#</strong> Sol Sib Do. En este caso, el Fa aparecería natural y, al formar la cuarta aumentada, aparecería un Fa#. Esta cuarta aumentada obtiene el nombre de blue note, ya que es la nota que caracteriza a esta escala.</li>
      </ul>
      <p>Un buen ejercicio para practicar escalas es calcular los intervalos que se usan para formar esas escalas hasta memorizarlas.</p>
      `,
      naturalNotesImg: 'natural_notes_es.png',
      alteredNotesImg: 'altered_notes_es.png'
    },
    greekModes: {
      title: 'Modos griegos',
      content: `<p>Los modos griegos son un conjunto de escalas. Existen 7 modos griegos y cada uno de ellos es identificado con un esquema de distancia tonal o intervalos único.</p>
      <p>El modo más sencillo para comprender los modos griegos es a través de las notas naturales. Si tomamos como nota fundamental cada una de las notas naturales (Do, Re, Mi, Fa, Sol, La y Si) hasta completar la octava, obtendremos un esquema tonal diferente desde cada uno de ellos.</p>
      <p>Los modos griegos se construyen siguiendo el siguiente esquema tonal:</p>
      <ul>
        <li><strong>Jónico:</strong> esquema tonal que sigue la sucesión de notas naturales usando Do como fundamental, hasta el siguiente Do ascendente (T-T-ST-T-T-T-ST).</li>
        <li><strong>Dórico:</strong> esquema tonal que sigue la sucesión de notas naturales usando Re como fundamental, hasta el siguiente Re ascendente (T-ST-T-T-T-ST-T).</li>
        <li><strong>Frigio:</strong> esquema tonal que sigue la sucesión de notas naturales usando Mi como fundamental, hasta el siguiente Mi ascendente (ST-T-T-T-ST-T-T).</li>
        <li><strong>Lidio:</strong> esquema tonal que sigue la sucesión de notas naturales usando Fa como fundamental, hasta el siguiente Fa ascendente (T-T-T-ST-T-T-ST).</li>
        <li><strong>Mixolidio:</strong> esquema tonal que sigue la sucesión de notas naturales usando Sol como fundamental, hasta el siguiente Sol ascendente (T-T-ST-T-T-ST-T).</li>
        <li><strong>Eolio:</strong> esquema tonal que sigue la sucesión de notas naturales usando La como fundamental, hasta el siguiente La ascendente (T-ST-T-T-ST-T-T).</li>
        <li><strong>Locrio:</strong> esquema tonal que sigue la sucesión de notas naturales usando Si como fundamental, hasta el siguiente Si ascendente (ST-T-T-ST-T-T-T).</li>
      </ul>
      `
    },
    settings: {
      title: 'Ajustes'
    },
  },
  colorSelector: 'Color principal'
}