<template>
  <template v-if="popupState === POPUP_STATES.INFO">
    <div
      class="popup-body__paragraph"
      v-html="$t('popupContent.help.content')"
    />
  </template>
  <template v-if="popupState === POPUP_STATES.INTRODUCTION">
    <div
      class="popup-body__paragraph"
      v-html="$t('popupContent.introduction.content')"
    />
  </template>
  <template v-else-if="popupState === POPUP_STATES.INTERVAL">
    <div
      class="popup-body__paragraph"
      v-html="$t('popupContent.intervals.content')"
    />
    <a
      :href="require(`@/assets/intervals/${$t('popupContent.intervals.tableName')}`)"
      :download="$t('popupContent.intervals.tableName')"
    >
      <img
        class="popup-body__interval-table"
        :src="require(`@/assets/intervals/${$t('popupContent.intervals.tableName')}`)"
        alt="Interval table"
      />
    </a>
  </template>
  <template v-else-if="popupState === POPUP_STATES.INVERT_INTERVAL">
    <div
      class="popup-body__paragraph"
      v-html="$t('popupContent.invertedIntervals.content', {
        invertedIntervalsTable: require(`@/assets/intervals/${$t('popupContent.invertedIntervals.tableName')}`)
      })"
    />
    <a
      :href="require(`@/assets/intervals/${$t('popupContent.invertedIntervals.tableName')}`)"
      :download="$t('popupContent.invertedIntervals.tableName')"
    >
      <img
        class="popup-body__interval-table"
        :src="require(`@/assets/intervals/${$t('popupContent.invertedIntervals.tableName')}`)"
        alt="Inverted intervals table"
      />
    </a>
  </template>
  <template v-else-if="popupState === POPUP_STATES.CHORD">
    <div
      class="popup-body__paragraph"
      v-html="$t('popupContent.chords.content')"
    />
  </template>
  <template v-else-if="popupState === POPUP_STATES.CHORD_STATE">
    <div
      class="popup-body__paragraph"
      v-html="$t('popupContent.chordStates.content', {
        rootStateImg: require(`@/assets/chords/${$t('popupContent.chordStates.rootStateImg')}`),
        firstInversionImg: require(`@/assets/chords/${$t('popupContent.chordStates.firstInversionImg')}`),
        secondInversionImg: require(`@/assets/chords/${$t('popupContent.chordStates.secondInversionImg')}`)
      })"
    />
  </template>
  <template v-else-if="popupState === POPUP_STATES.SCALE">
    <div
      class="popup-body__paragraph"
      v-html="$t('popupContent.scales.content', {
        naturalNotesImg: require(`@/assets/scales/${$t('popupContent.scales.naturalNotesImg')}`),
        alteredNotesImg: require(`@/assets/scales/${$t('popupContent.scales.alteredNotesImg')}`)
      })"
    />
  </template>
  <template v-else-if="popupState === POPUP_STATES.GREEK_MODE">
    <div
      class="popup-body__paragraph"
      v-html="$t('popupContent.greekModes.content')"
    />
  </template>
  <template v-else-if="popupState === POPUP_STATES.SETTINGS">
    <div class="popup-body__setting-container-wrapper">
      <div class="popup-body__settings-row">
        <span>{{ $t('showNoteNames') }}</span>
        <input-switch
          :checked="showNotesNames"
          @input="changeShowNotesNames"
        />
      </div>
      <div class="popup-body__settings-row">
        <span>{{ $t('useAmericanNotation') }}</span>
        <input-switch
          :checked="selectedNotation === NOTATION_NAMES.ENGLISH"
          @input="changeSelectedNotation"
        />
      </div>
      <div class="popup-body__settings-row">
        <span>{{ $t('showStaff') }}</span>
        <input-switch
          :checked="showStaff === true"
          @input="changeShowStaff"
        />
      </div>
      <div class="popup-body__settings-row">
        <span>{{ $t('useDarkMode') }}</span>
        <input-switch
          :checked="isDarkModeEnabled"
          @input="toggleDarkMode"
        />
      </div>
      <div class="popup-body__settings-row">
        <span>{{$t('volume')}}</span>
        <volume-slider
          :value="parseFloat(selectedVolume)"
          @input="changeSelectedVolume"
        />
      </div>
      <div class="popup-body__settings-row">
        <span>{{$t('colorSelector')}}</span>
        <color-picker @select-color="changeSelectedColor" />
      </div>
    </div>
  </template>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useStore } from '../../../store'

import VolumeSlider from '@/components/generic/VolumeSlider.vue'
import InputSwitch from '@/components/generic/InputSwitch.vue'
import ColorPicker from '@/components/generic/ColorPicker.vue'

const { POPUP_STATES, NOTATION_NAMES } = require('../../../utils/constants')

export default {
  name: 'HeaderWrapper',
  components: {
    VolumeSlider,
    InputSwitch,
    ColorPicker
},

  computed: {
    ...mapState(useStore, ['popupState']),
    ...mapState(useStore, ['showNotesNames']),
    ...mapState(useStore, ['selectedNotation']),
    ...mapState(useStore, ['selectedVolume']),
    ...mapState(useStore, ['isDarkModeEnabled']),
    ...mapState(useStore, ['showStaff']),
    ...mapState(useStore, ['selectedColor'])
  },

  created() {
    this.NOTATION_NAMES = NOTATION_NAMES
    this.POPUP_STATES = POPUP_STATES
  },

  methods: {
    ...mapActions(useStore, ['changeSelectedNotation']),
    ...mapActions(useStore, ['changeSelectedVolume']),
    ...mapActions(useStore, ['changeShowNotesNames']),
    ...mapActions(useStore, ['changeSelectedColor']),
    ...mapActions(useStore, ['changeShowStaff']),
    ...mapActions(useStore, ['toggleDarkMode']),
  }
}
</script>
<style lang="scss">
.popup-body__interval-table{
  width: 100%;
}

.popup-body__chord_state_img{
  align-self: center;
}

.popup-body__paragraph{
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  user-select: text;
  letter-spacing: 0.5px;
  text-align: left;
  &:last-child{
    margin-bottom: 0px;
  }
  p, ul, ol {
    margin-top: 0;
    line-height: 20px;
  }
}

.popup-body__setting-container-wrapper {
  background: var(--background_color_secondary);
  border-radius: 5px;
}

.popup-body__settings-row {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
  height: 50px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: var(--separator_color);
}

.popup-body__settings-row:nth-of-type(1) {
  border-top: none;
}
.popup-body__settings-row:nth-of-type(6) {
  border-bottom: none;
}
</style>
