import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
//import VueGtag from "vue-gtag";

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faCircleInfo, faArrowRight, faGear, faXmark, faVolumeLow, faVolumeHigh, faExpand, faCompress, faChevronRight, faQuestion, faTrashCan } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faCircleInfo, faArrowRight, faGear, faXmark, faVolumeLow, faVolumeHigh, faExpand, faCompress, faChevronRight, faQuestion, faTrashCan)

const messages = require('./lang/translations')

const browserLanguage = (navigator.language || navigator.userLanguage || '').split('-')[0]
const i18n = createI18n({
  locale: browserLanguage, // set locale
  fallbackLocale: 'en', // set fallback locale
  messages
})

const pinia = createPinia()
const app = createApp(App)

app.component('font-awesome-icon', FontAwesomeIcon)
app.use(i18n)
app.use(pinia)
app.mount('#app')
