const {
  MAIN_CHORDS_NAMES,
  SEVENTH_NAMES,
  CHORD_STATES_NAMES,
  INTERVAL_NAMES,
  INTERVAL_TYPES,
  MODE_NAMES,
  SCALES_NAMES,
  GREEK_MODES_NAMES
} = require('../utils/constants')

module.exports = {
  showNoteNames: 'Show notes',
  selectChord: 'Chord',
  seventh: 'Seventh',
  chordState: 'State', // Check this translation
  mainChords: {
    [MAIN_CHORDS_NAMES.MINOR_TRIAD]: 'Minor triad',
    [MAIN_CHORDS_NAMES.MAJOR_TRIAD]: 'Major triad',
  },
  fourNoteChords: {
    [MAIN_CHORDS_NAMES.MINOR_TRIAD]: 'Minor tetrad', // Check this translation
    [MAIN_CHORDS_NAMES.MAJOR_TRIAD]: 'Major tetrad',
  },
  fourNoteChordsComplete: {
    [SEVENTH_NAMES.MINOR_SEVENTH]: '{chordName} with minor seventh',
    [SEVENTH_NAMES.MAJOR_SEVENTH]: '{chordName} with major seventh',
    //[SEVENTH_NAMES.DIMINISHED_SEVENTH]: '{chordName} with diminished seventh',
  },
  seventhNames: {
    [SEVENTH_NAMES.NO_SEVENTH]: 'Without seventh',
    [SEVENTH_NAMES.MINOR_SEVENTH]: 'Minor seventh',
    [SEVENTH_NAMES.MAJOR_SEVENTH]: 'Major seventh',
    //[SEVENTH_NAMES.DIMINISHED_SEVENTH]: 'Diminished seventh',
  },
  chordStates: {
    [CHORD_STATES_NAMES.NATURAL_STATE]: 'Root position',
    [CHORD_STATES_NAMES.FIRST_INVERSION]: 'First inversion',
    [CHORD_STATES_NAMES.SECOND_INVERSION]: 'Second inversion',
    [CHORD_STATES_NAMES.THIRD_INVERSION]: 'Third inversion',
  },
  notesOutWarning: 'Be carefull! There are notes outside the piano that can not be displayed',
  select: 'Select',
  play: 'Listen',
  playArpeggiated: 'Listen arpeggiated',
  interval: 'Interval',
  intervalNames: {
    [INTERVAL_NAMES.MINOR_SECOND]: 'Minor second',
    [INTERVAL_NAMES.MAJOR_SECOND]: 'Major second',
    [INTERVAL_NAMES.AUGMENTED_SECOND]: 'Augmented second',
    [INTERVAL_NAMES.MINOR_THIRD]: 'Minor third',
    [INTERVAL_NAMES.MAJOR_THIRD]: 'Major third',
    [INTERVAL_NAMES.PERFECT_FOURTH]: 'Perfect fourth',
    [INTERVAL_NAMES.AUGMENTED_FOURTH]: 'Augmented fourth',
    [INTERVAL_NAMES.DIMINISHED_FIFTH]: 'Diminished fifth',
    [INTERVAL_NAMES.PERFECT_FIFTH]: 'Perfect fifth',
    [INTERVAL_NAMES.MINOR_SIXTH]: 'Minor sixth',
    [INTERVAL_NAMES.MAJOR_SIXTH]: 'Major sixth',
    [INTERVAL_NAMES.DIMINISHED_SEVENTH]: 'Diminished seventh',
    [INTERVAL_NAMES.MINOR_SEVENTH]: 'Minor seventh',
    [INTERVAL_NAMES.MAJOR_SEVENTH]: 'Major seventh',
    [INTERVAL_NAMES.PERFECT_OCTAVE]: 'Perfect octave'
  },
  intervalOrder: 'Order',
  intervalTypes: {
    [INTERVAL_TYPES.DOWNWARD]: 'Downward',
    [INTERVAL_TYPES.UPWARD]: 'Upward',
  },
  invertInterval: 'Invert interval',
  selectMode: 'Use mode',
  modeNames: {
    [MODE_NAMES.INTERVAL]: 'Interval',
    [MODE_NAMES.CHORD]: 'Chord',
    [MODE_NAMES.SCALE]: 'Scale',
    [MODE_NAMES.GREEK_MODE]: 'Greek mode',
  },
  scale: 'Scale',
  scaleNames: {
    [SCALES_NAMES.MAJOR]: 'Major',
    [SCALES_NAMES.NATURAL_MINOR]: 'Natural minor',
    [SCALES_NAMES.HARMONIC_MINOR]: 'Harmonic minor',
    [SCALES_NAMES.MELODIC_MINOR]: 'Melodic minor',
    [SCALES_NAMES.PENTATONIC_MAJOR]: 'Pentatonic major',
    [SCALES_NAMES.PENTATONIC_MINOR]: 'Pentatonic minor',
    [SCALES_NAMES.BLUES_SCALE]: 'Blues scale'
  },
  greekMode: 'Greek mode',
  greekModeNames: {
    [GREEK_MODES_NAMES.IONIAN]: 'Ionian',
    [GREEK_MODES_NAMES.DORIAN]: 'Dorian',
    [GREEK_MODES_NAMES.PHRYGIAN]: 'Phrygian',
    [GREEK_MODES_NAMES.LYDIAN]: 'Lydian',
    [GREEK_MODES_NAMES.MIXOLYDIAN]: 'Mixolydian',
    [GREEK_MODES_NAMES.AEOLIAN]: 'Aeolian',
    [GREEK_MODES_NAMES.LOCRIAN]: 'Locrian'
  },
  invertedInterval: 'Inverted interval',
  tones: '{tones} tone | {tones} tones',
  semitones: '{semitones} semitone | {semitones} semitones',
  and: ' and ',
  intervalInfo: '{intervalTitle}: {interval} ({tones}) {intervalType} of {note}, formed by the notes: {notes}',
  chordInfo: '{chordTitle}: {chord} of {note} on {chordState}, formed by the notes: {notes}',
  scaleInfo: '{scaleTitle} {scale} of {note}, formed by the notes: {notes}',
  volume: 'Volume',
  portraitModeDisabled: 'Portrait mode is disabled for this device, please switch to landscape to use the app.',
  freeModeWarning: 'While "Listen" is enabled you won\'t be able to select any interval, chord or scale when pressing keys.',
  help1: 'Piano Basics is a website that helps music students in learning intervals, chords and scales. ',
  help2: 'If you would like to see an introduction to music theory, click on the question mark.',
  contact: 'Contact: ',
  moreOptions: 'More options',
  useAmericanNotation: 'Use American notation',
  showStaff: 'Show staff',
  useDarkMode: 'Use dark mode',
  popupContent: {
    help: {
      title: 'Welcome to PianoBasics',
      content: `<p>Piano Basics is a website that helps music students in learning intervals, chords and scales.</p>
      <p>Using these selectors, you can form and listen to the combinations of intervals, chords or scales you need to practice. In each of the help buttons you have available a summary of the theory of the corresponding mode.</p>
      <p>If you have any suggestions, please do not hesitate to contact us by any of the means listed at the bottom of the page.</p>`
    },
    introduction: {
      title: 'Introduction',
      content: `<p>Music theory is the study of the different elements of music. PianoBasics will help us to understand how the different sounds of the notes are organized and how intervals, chords or scales are formed, using the piano as a visual element.</p>
      <h3>Notes</h3>
      <p>All music is composed of notes, as this is the fundamental building block on which music is based. A note is the name we give to a specific sound (a defined frequency). There are seven musical notes: Do, Re, Mi, Fa, Sol, La Si and Do (in American notation: C, D, E, F, G, A, B and C in the same order).</p>
      <div>The above notes are called <strong>natural notes</strong> and are represented on a piano by the white keys. It is possible to raise or lower semitones to these notes to form <strong>altered notes</strong>:
      <ul>
        <li>if we raise semitones we speak of sharps</li>
        <li>if we go down semitones we talk about flats</li>
        <li>there are also double sharps and double flats, but these are less common</li>
      </ul>
      </div>
      <p>If you activate the "show notes" option in the settings, you will be able to see the notes represented on the piano.</p>
      <p>How are the notes represented on a piano? The piano is structured in octaves, being an octave all the keys between a C and the following ascending or descending C.</p>
      <h3>Tonal distance</h3>
      <p>The <strong>tonal distance</strong> is measured in semitones and/or tones (1 tone equals 2 semitones, being a semitone the minimum distance between two notes). Calculating the distance in semitones in piano is very simple, since between each of the keys there is 1 semitone of distance (including the black keys). For example, between C and E in ascending order there are 4 semitones (2 tones), since there are 4 displacements between the two notes (From C to C#, from C# to D, from D to D# and from D# to E).</p>
      <p>With this short introduction to what notes are, how they are represented on a piano and tonal distance, we are ready to use PianoBasics to learn about intervals, chords and scales.</p>
      `,
    },
    intervals: {
      title: 'Music intervals',
      content: `<p>An interval is the difference in height between two notes. To define them we need to use three concepts: height, tonal distance and their order.</p>
      <p>The <strong>height</strong> is measured by counting how many <strong>natural notes</strong> there are between the two notes that define the interval (including the two notes).</p>
      <p>For example, between C and F in ascending order, we have four natural height notes: C, D, E and F. Another example would be C and F# in ascending order, in which there are also four natural height notes: C, D, E and F (we do not care about the sharp of F since we use natural notes).</p>
      <p>The <strong>species</strong> of the interval is defined by the tonal distance, that is, the number of tones and semitones between the two notes. The types of species are: major, minor, just, augmented or diminished.</p>
      <p>The <strong>order</strong> of an interval can be ascending or descending. Taking a note as a reference, if the interval is ascending it moves towards higher notes and if it is descending towards lower notes.</p>
      <p>In the following table you can see the main musical intervals with their tonal distance (in tones and semitones) and their pitch, if you wish you can download it by clicking on the image.</p>
      `,
      tableName: 'interval_table_en.jpg'
    },
    invertedIntervals: {
      title: 'Interval inversion',
      content: `<p>Inverting an interval consists of raising the lowest note of the interval by one octave, changing the height, the tonal distance and the species of the interval.</p>
      <p>The perfect octave interval is the only one that maintains its tonal distance, pitch and species when inverted.</p>
      <p>For the rest of the intervals, when inverted, it is always true:</p>
      <ul>
        <li>The height of the interval plus that of the inverted interval is always nine (e.g., an inversion of a second interval will always be a seventh)</li>
        <li>As for the species, by inverting an interval:
          <ul>
            <li>major becomes minor</li>
            <li>minor becomes major</li>
            <li>augmented becomes diminished</li>
            <li>diminished becomes augmented</li>
            <li>perfect remains perfect</li>
          </ul>
        </li>
      </ul>
      <p>In the following table you can see the main musical intervals with their inversions. You can download it by clicking on the image.</p>
      `,
      tableName: 'inverted_interval_table_en.jpg'
    },
    chords: {
      title: 'Chords',
      content: `<p>A chord is the combination of two or more different notes. It is important to know interval theory since chords are built from a <strong>root note</strong> (the note that defines the chord) and two or more intervals from it. Chords will always be built in ascending order.</p>
      <p>To begin with, let's focus on three-note chords, called <strong>triad chords</strong>. Specifically the major triad and the minor triad.</p>
      <h3>Root position</h3>
      <p>A <strong>major triad</strong> chord (major chord) is composed of its root note, a <strong>major</strong> third interval and a perfect fifth interval (both ascending) starting from the root note. Example of how to build a major chord:</p>
      <ol>
        <li>We choose a fundamental note, for example C</li>
        <li>We calculate the ascending major third interval of C: E</li>
        <li>We calculate the interval of the ascending perfect fifth from C: G</li>
      </ol>
      <p>Therefore, the C major triad chord is formed by the notes C, E and G.</p>
      <h3>Minor triad</h3>
      <p>A <strong>minor triad</strong> chord (minor chord) is defined by its root note, a <strong>minor</strong> third interval and a perfect fifth interval (both ascending) from the root note. Example of how to build a minor chord:</p>
      <ol>
        <li>We choose a fundamental note, for example C</li>
        <li>We calculate the interval of the ascending minor third of C: E flat</li>
        <li>We calculate the interval of the ascending perfect fifth from C: G</li>
      </ol>
      <p>Therefore, the C minor triad chord is formed by the notes C, E flat and G.</p>
      <p>As we can see, the only change between a major and a minor triad is the third interval used to form the chord.</p>`
    },
    chordStates: {
      title: 'Chord state',
      content: `<p>The state of a chord defines the arrangement of the notes that compose it. In the case of triad chords, being formed by three notes, we can represent them in three ways: fundamental state, first inversion and second inversion.
      In the following, the representations of these three states will be made taking as an example a C major triad chord in closed position, that is to say, playing the notes of the chord notes closest to each other.</p>
      <h3>Root position</h3>
      <p>In the root position, the fundamental note will be the lowest note of the chord (i.e., as we have represented it in the previous section).</p>
      <img class="popup-body__chord_state_img" src={rootStateImg} alt="Root state chord"></img>
      <h3>First inversion</h3>
      <p>The first inversion shifts the root note one octave in ascending order, so the second note of the chord (third interval) is the lowest note of the chord. In a C major chord (C, E and G) in first inversion, the lowest note would be the E, since we have shifted the C.</p>
      <img class="popup-body__chord_state_img" src={firstInversionImg} alt="First inversion chord"></img>
      <h3>Second inversion</h3>
      <p>The second inversion shifts the first two notes of the chord (root note and second note of the chord) one octave in ascending order, so the third note of the chord (fifth interval) is the lowest note of the chord. In a C major chord (C, E and G) in second inversion, the lowest note would be the G, since we have displaced the C and the E.</p>
      <img class="popup-body__chord_state_img" src={secondInversionImg} alt="Second inversion chord"></img>
      `,
      rootStateImg: 'root_position_en.png',
      firstInversionImg: 'first_inversion_en.png',
      secondInversionImg: 'second_inversion_en.png'
    },
    scales: {
      title: 'Scales',
      content: `<p>A musical scale is a succession of notes that follows a scheme. The starting note is defined as the fundamental.</p>
      <p><strong>The scales are built always thinking in ascending order.</strong></p>
      <h3>Main scales</h3>
      <p>The main scales are the major and minor scales. The rules for constructing these scales are:</p>
      <ul>
        <li>The scale has to go from octave to octave (for example, if the root note is C, the scale would end on the ascending C). The root note would give the scale its name, for example: C major scale.</li>
        <li>These scales are composed of 8 notes. The only repeated note is the root note (in this case, the C).</li>
        <li>The rest of the notes must appear in their entirety, in successive ascending order (in the case of the C major scale, C D E F G A B C). These notes must not be repeated. For example, in a C major scale, an E and an E# should not appear. The E# would get the designation of F, and so on.</li>
        <img class="popup-body__chord_state_img" src={naturalNotesImg} alt="Natural notes scale"></img>
        <li>In case the natural notes were not consistent with the scheme, they could be altered. For example: in the G major scale, for the scheme to be T-T-ST-T-T-T-ST, the F would have to be altered one semitone upward.
        The seventh note could not be G flat, since the above rule indicates that all notes between G and G must appear in successive ascending order. Therefore, the notes of the G major scale should be: G A B C D E <strong>F#</strong> G.</li>
        <img class="popup-body__chord_state_img" src={alteredNotesImg} alt="Altered notes scale"></img>
      </ul>
      <p>To define the scheme of these scales we will use the tonal distance.</p>
      <ul>
        <li><strong>Major scale:</strong> (T-T-ST-T-T-T-ST)</li>
        <li><strong>Natural minor scale:</strong> (T-ST-T-T-ST-T-T)</li>
        <li><strong>Harmonic minor scale:</strong> natural minor scale, but the 7th with respect to the fundamental would ascend a semitone, becoming a Major 7th.</li>
        <li><strong>Melodic minor scale:</strong> natural minor scale, but the 6th and 7th with respect to the fundamental would be major intervals, ascending one semitone with respect to the natural minor scale.</li>
      </ul>
      <h3>Other scales</h3>
      <p>There are other scales included in this version of PianoBasics that do not comply with the rules of the major and minor scales, which are as follows:</p>
      <ul>
        <li><strong>Major pentatonic scale:</strong> similar to the Major scale, but omitting the fourth and seventh notes of the scale. For example, the C major pentatonic scale would have the following notes: C D E G A C.</li>
        <li><strong>Minor pentatonic scale:</strong> similar to the minor scale, but omitting the second and sixth notes of the scale. For example, the C minor pentatonic scale would have the following notes: C Eb F G Bb C.</li>
        <li><strong>Blues scale:</strong> similar to the minor pentatonic scale, but adding an augmented fourth (4thA) with respect to the root note between the third and fourth notes. This is the first scale in which there are two repeated notes. In the case of the C blues scale, we would find the following notes: C Eb F <strong>F#</strong> G Bb C. In this case, the F would appear natural and, when forming the augmented fourth, an F# would appear. This augmented fourth gets the name of blue note, since it is the note that characterizes this scale.</li>
      </ul>
      <p>A good exercise to practice scales is to calculate the intervals used to form those scales until they are memorized.</p>
      `,
      naturalNotesImg: 'natural_notes_en.png',
      alteredNotesImg: 'altered_notes_en.png'
    },
    greekModes: {
      title: 'Scales',
      content: `<p>Greek modes are a set of scales. There are 7 Greek modes and each of them is identified with a unique tonal distance or interval scheme.</p>
      <p>The easiest way to understand the Greek modes is through the natural notes. If we take as fundamental note each of the natural notes (C, D, E, F, G, A and B) until completing the octave, we will obtain a different tonal scheme from each of them.</p>
      <p>The Greek modes are as follows:</p>
      <ul>
        <li><strong>Ionian:</strong> tonal scheme following the succession of natural notes using C as the fundamental, up to the next ascending C (T-T-ST-T-T-T-ST).</li>
        <li><strong>Dorian:</strong> tonal scheme that follows the succession of natural notes using D as the fundamental, up to the next ascending D (T-ST-T-T-T-ST-T).</li>
        <li><strong>Phrygian:</strong> tonal scheme that follows the succession of natural notes using E as the fundamental, up to the next ascending E (ST-T-T-T-ST-T-T).</li>
        <li><strong>Lydian:</strong> tonal scheme that follows the succession of natural notes using F as the fundamental, up to the next ascending F (T-T-T-ST-T-T-ST).</li>
        <li><strong>Mixolydian:</strong> tonal scheme that follows the succession of natural notes using G as the fundamental, up to the next rising G (T-T-ST-T-T-ST-T).</li>
        <li><strong>Aeolian:</strong> tonal scheme that follows the succession of natural notes using A as the fundamental, up to the next ascending A (T-ST-T-T-ST-T-T).</li>
        <li><strong>Locrian:</strong> tonal scheme that follows the succession of natural notes using B as the fundamental, up to the next ascending B (ST-T-T-ST-T-T-T).</li>
      </ul>
      `
    },
    settings: {
      title: 'Settings'
    }
  },
  colorSelector: 'Main color'
}