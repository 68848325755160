const KEY_TYPES = {
  BLACK: 'black',
  WHITE: 'white'
}

const NOTES_LIST = ['Do', 'Re', 'Mi', 'Fa', 'Sol', 'La', 'Si']

const OCTAVE_KEYS = [
  {
    notes: ['Do', 'Si#', 'Laˣ', 'Rebb'],
    type: KEY_TYPES.WHITE,
    hasOffset: false
  },
  {
    notes: ['Do#', 'Reb', 'Siˣ'],
    type: KEY_TYPES.BLACK,
    hasOffset: true
  },
  {
    notes: ['Re', 'Doˣ', 'Mibb'],
    type: KEY_TYPES.WHITE,
    hasOffset: true
  },
  {
    notes: ['Re#', 'Mib', 'Fabb'],
    type: KEY_TYPES.BLACK,
    hasOffset: true
  },
  {
    notes: ['Mi', 'Fab', 'Reˣ'],
    type: KEY_TYPES.WHITE,
    hasOffset: true
  },
  {
    notes: ['Fa', 'Mi#', 'Solbb'],
    type: KEY_TYPES.WHITE,
    hasOffset: false
  },
  {
    notes: ['Fa#', 'Solb', 'Miˣ'],
    type: KEY_TYPES.BLACK,
    hasOffset: true
  },
  {
    notes: ['Sol', 'Faˣ', 'Labb'],
    type: KEY_TYPES.WHITE,
    hasOffset: true
  },
  {
    notes: ['Sol#', 'Lab'],
    type: KEY_TYPES.BLACK,
    hasOffset: true
  },
  {
    notes: ['La', 'Solˣ', 'Sibb'],
    type: KEY_TYPES.WHITE,
    hasOffset: true
  },
  {
    notes: ['La#', 'Sib', 'Dobb'],
    type: KEY_TYPES.BLACK,
    hasOffset: true
  },
  {
    notes: ['Si', 'Dob', 'Laˣ'],
    type: KEY_TYPES.WHITE,
    hasOffset: true
  }
]

const MODE_NAMES = {
  INTERVAL: 'interval',
  CHORD: 'chord',
  SCALE: 'scale',
  GREEK_MODE: 'greek_mode'
}

const HAS_MORE_OPTIONS_BY_MODE = {
  [MODE_NAMES.INTERVAL]: true,
  [MODE_NAMES.CHORD]: true,
  [MODE_NAMES.SCALE]: false,
  [MODE_NAMES.GREEK_MODE]: false
}

const POPUP_STATES = {
  INFO: 'info',
  INTRODUCTION: 'introduction',
  INTERVAL: 'interval',
  INVERT_INTERVAL: 'invert_interval',
  CHORD: 'chord',
  CHORD_STATE: 'chord_state',
  SCALE: 'scale',
  GREEK_MODE: 'greek_mode',
  SETTINGS: 'settings'
}

const INTERVAL_NAMES = {
  MINOR_SECOND: 'minor_second',
  MAJOR_SECOND: 'major_second',
  AUGMENTED_SECOND: 'augmented_second',
  MINOR_THIRD: 'minor_third',
  MAJOR_THIRD: 'major_third',
  PERFECT_FOURTH: 'perfect_fourth',
  AUGMENTED_FOURTH: 'augmented_fourth',
  DIMINISHED_FIFTH: 'diminished_fifth',
  PERFECT_FIFTH: 'perfect_fifth',
  MINOR_SIXTH: 'minor_sixth',
  MAJOR_SIXTH: 'major_sixth',
  DIMINISHED_SEVENTH: 'diminished_seventh',
  MINOR_SEVENTH: 'minor_seventh',
  MAJOR_SEVENTH: 'major_seventh',
  PERFECT_OCTAVE: 'perfect_octave'
}

const INTERVAL_TYPES = {
  UPWARD: 'upward',
  DOWNWARD: 'downward'
}

const MAIN_CHORDS_NAMES = {
  MAJOR_TRIAD: 'major_triad',
  MINOR_TRIAD: 'minor_triad'
}

const SEVENTH_NAMES = {
  NO_SEVENTH: 'no_seventh',
  MINOR_SEVENTH: 'minor_seventh',
  MAJOR_SEVENTH: 'major_seventh',
  //DIMINISHED_SEVENTH: 'diminished_seventh'
}

const EXTRA_CHORDS_NAMES = {
  MINOR_TRIAD_MINOR_SEVENTH: 'minor_triad_minor_seventh',
  MINOR_TRIAD_MAJOR_SEVENTH: 'minor_triad_major_seventh',
  MAJOR_TRIAD_MINOR_SEVENTH: 'major_triad_minor_seventh',
  MAJOR_TRIAD_MAJOR_SEVENTH: 'major_triad_major_seventh',
  MINOR_TRIAD_DIMINISHED_SEVENTH: 'minor_triad_diminished_seventh',
}

const CHORDS = {
  [MAIN_CHORDS_NAMES.MAJOR_TRIAD]: [INTERVAL_NAMES.MAJOR_THIRD, INTERVAL_NAMES.PERFECT_FIFTH],
  [MAIN_CHORDS_NAMES.MINOR_TRIAD]: [INTERVAL_NAMES.MINOR_THIRD, INTERVAL_NAMES.PERFECT_FIFTH]
}

const SEVENTH_EXTRA_INTERVAL = {
  [SEVENTH_NAMES.NO_SEVENTH]: [],
  [SEVENTH_NAMES.MINOR_SEVENTH]: [INTERVAL_NAMES.MINOR_SEVENTH],
  [SEVENTH_NAMES.MAJOR_SEVENTH]: [INTERVAL_NAMES.MAJOR_SEVENTH],
  //[SEVENTH_NAMES.DIMINISHED_SEVENTH]: [INTERVAL_NAMES.DIMINISHED_SEVENTH]
}

const CHORD_STATES_NAMES = {
  NATURAL_STATE: 'natural_state',
  FIRST_INVERSION: 'first_inversion',
  SECOND_INVERSION: 'second_inversion',
  THIRD_INVERSION: 'third_inversion'
}

const CHORD_STATES_ROTATIONS = {
  [CHORD_STATES_NAMES.NATURAL_STATE]: 0,
  [CHORD_STATES_NAMES.FIRST_INVERSION]: 1,
  [CHORD_STATES_NAMES.SECOND_INVERSION]: 2,
  [CHORD_STATES_NAMES.THIRD_INVERSION]: 3
}

const INTERVALS = {
  [INTERVAL_NAMES.MINOR_SECOND]: {
    semitones: 1,
    notesInterval: 1
  },
  [INTERVAL_NAMES.MAJOR_SECOND]: {
    semitones: 2,
    notesInterval: 1
  },
  [INTERVAL_NAMES.AUGMENTED_SECOND]: {
    semitones: 3,
    notesInterval: 1
  },
  [INTERVAL_NAMES.MINOR_THIRD]: {
    semitones: 3,
    notesInterval: 2
  },
  [INTERVAL_NAMES.MAJOR_THIRD]: {
    semitones: 4,
    notesInterval: 2
  },
  [INTERVAL_NAMES.PERFECT_FOURTH]: {
    semitones: 5,
    notesInterval: 3
  },
  [INTERVAL_NAMES.AUGMENTED_FOURTH]: {
    semitones: 6,
    notesInterval: 3
  },
  [INTERVAL_NAMES.DIMINISHED_FIFTH]: {
    semitones: 6,
    notesInterval: 4
  },
  [INTERVAL_NAMES.PERFECT_FIFTH]: {
    semitones: 7,
    notesInterval: 4
  },
  [INTERVAL_NAMES.MINOR_SIXTH]: {
    semitones: 8,
    notesInterval: 5
  },
  [INTERVAL_NAMES.MAJOR_SIXTH]: {
    semitones: 9,
    notesInterval: 5
  },
  [INTERVAL_NAMES.DIMINISHED_SEVENTH]: {
    semitones: 9,
    notesInterval: 6
  },
  [INTERVAL_NAMES.MINOR_SEVENTH]: {
    semitones: 10,
    notesInterval: 6
  },
  [INTERVAL_NAMES.MAJOR_SEVENTH]: {
    semitones: 11,
    notesInterval: 6
  },
  [INTERVAL_NAMES.PERFECT_OCTAVE]: {
    semitones: 12,
    notesInterval: 7
  }
}

const NOTATION_NAMES = {
  LATIN: 'latin',
  ENGLISH: 'english'
}

const NOTES_BY_NOTATION_NAME = {
  [NOTATION_NAMES.ENGLISH]: {
    [NOTES_LIST[0]]: 'C',
    [NOTES_LIST[1]]: 'D',
    [NOTES_LIST[2]]: 'E',
    [NOTES_LIST[3]]: 'F',
    [NOTES_LIST[4]]: 'G',
    [NOTES_LIST[5]]: 'A',
    [NOTES_LIST[6]]: 'B'
  }
}

const SCALES_NAMES = {
  MAJOR: 'major',
  NATURAL_MINOR: 'natural_minor',
  HARMONIC_MINOR: 'harmonic_minor',
  MELODIC_MINOR: 'melodic_minor',
  PENTATONIC_MAJOR: 'pentatonic_major',
  PENTATONIC_MINOR: 'pentatonic_minor',
  BLUES_SCALE: 'blues_scale'
}

const SCALES = {
  [SCALES_NAMES.MAJOR]: [
    INTERVAL_NAMES.MAJOR_SECOND,
    INTERVAL_NAMES.MAJOR_THIRD,
    INTERVAL_NAMES.PERFECT_FOURTH,
    INTERVAL_NAMES.PERFECT_FIFTH,
    INTERVAL_NAMES.MAJOR_SIXTH,
    INTERVAL_NAMES.MAJOR_SEVENTH,
    INTERVAL_NAMES.PERFECT_OCTAVE
  ],
  [SCALES_NAMES.NATURAL_MINOR]: [
    INTERVAL_NAMES.MAJOR_SECOND,
    INTERVAL_NAMES.MINOR_THIRD,
    INTERVAL_NAMES.PERFECT_FOURTH,
    INTERVAL_NAMES.PERFECT_FIFTH,
    INTERVAL_NAMES.MINOR_SIXTH,
    INTERVAL_NAMES.MINOR_SEVENTH,
    INTERVAL_NAMES.PERFECT_OCTAVE
  ],
  [SCALES_NAMES.HARMONIC_MINOR]: [
    INTERVAL_NAMES.MAJOR_SECOND,
    INTERVAL_NAMES.MINOR_THIRD,
    INTERVAL_NAMES.PERFECT_FOURTH,
    INTERVAL_NAMES.PERFECT_FIFTH,
    INTERVAL_NAMES.MINOR_SIXTH,
    INTERVAL_NAMES.MAJOR_SEVENTH,
    INTERVAL_NAMES.PERFECT_OCTAVE
  ],
  [SCALES_NAMES.MELODIC_MINOR]: [
    INTERVAL_NAMES.MAJOR_SECOND,
    INTERVAL_NAMES.MINOR_THIRD,
    INTERVAL_NAMES.PERFECT_FOURTH,
    INTERVAL_NAMES.PERFECT_FIFTH,
    INTERVAL_NAMES.MAJOR_SIXTH,
    INTERVAL_NAMES.MAJOR_SEVENTH,
    INTERVAL_NAMES.PERFECT_OCTAVE
  ],
  [SCALES_NAMES.PENTATONIC_MAJOR]: [
    INTERVAL_NAMES.MAJOR_SECOND,
    INTERVAL_NAMES.MAJOR_THIRD,
    INTERVAL_NAMES.PERFECT_FIFTH,
    INTERVAL_NAMES.MAJOR_SIXTH,
    INTERVAL_NAMES.PERFECT_OCTAVE
  ],
  [SCALES_NAMES.PENTATONIC_MINOR]: [
    INTERVAL_NAMES.MINOR_THIRD,
    INTERVAL_NAMES.PERFECT_FOURTH,
    INTERVAL_NAMES.PERFECT_FIFTH,
    INTERVAL_NAMES.MINOR_SEVENTH,
    INTERVAL_NAMES.PERFECT_OCTAVE
  ],
  [SCALES_NAMES.BLUES_SCALE]: [
    INTERVAL_NAMES.MINOR_THIRD,
    INTERVAL_NAMES.PERFECT_FOURTH,
    INTERVAL_NAMES.AUGMENTED_FOURTH,
    INTERVAL_NAMES.PERFECT_FIFTH,
    INTERVAL_NAMES.MINOR_SEVENTH,
    INTERVAL_NAMES.PERFECT_OCTAVE
  ]
}

const GREEK_MODES_NAMES = {
  IONIAN: 'ionian',
  DORIAN: 'dorian',
  PHRYGIAN: 'phrygian',
  LYDIAN: 'lydian',
  MIXOLYDIAN: 'mixolydian',
  AEOLIAN: 'aeloian',
  LOCRIAN: 'locrian'
}

const GREEK_MODES = {
  [GREEK_MODES_NAMES.IONIAN]: [
    INTERVAL_NAMES.MAJOR_SECOND,
    INTERVAL_NAMES.MAJOR_THIRD,
    INTERVAL_NAMES.PERFECT_FOURTH,
    INTERVAL_NAMES.PERFECT_FIFTH,
    INTERVAL_NAMES.MAJOR_SIXTH,
    INTERVAL_NAMES.MAJOR_SEVENTH,
    INTERVAL_NAMES.PERFECT_OCTAVE
  ],
  [GREEK_MODES_NAMES.DORIAN]: [
    INTERVAL_NAMES.MAJOR_SECOND,
    INTERVAL_NAMES.MINOR_THIRD,
    INTERVAL_NAMES.PERFECT_FOURTH,
    INTERVAL_NAMES.PERFECT_FIFTH,
    INTERVAL_NAMES.MAJOR_SIXTH,
    INTERVAL_NAMES.MINOR_SEVENTH,
    INTERVAL_NAMES.PERFECT_OCTAVE
  ],
  [GREEK_MODES_NAMES.PHRYGIAN]: [
    INTERVAL_NAMES.MINOR_SECOND,
    INTERVAL_NAMES.MINOR_THIRD,
    INTERVAL_NAMES.PERFECT_FOURTH,
    INTERVAL_NAMES.PERFECT_FIFTH,
    INTERVAL_NAMES.MINOR_SIXTH,
    INTERVAL_NAMES.MINOR_SEVENTH,
    INTERVAL_NAMES.PERFECT_OCTAVE
  ],
  [GREEK_MODES_NAMES.LYDIAN]: [
    INTERVAL_NAMES.MAJOR_SECOND,
    INTERVAL_NAMES.MAJOR_THIRD,
    INTERVAL_NAMES.AUGMENTED_FOURTH,
    INTERVAL_NAMES.PERFECT_FIFTH,
    INTERVAL_NAMES.MAJOR_SIXTH,
    INTERVAL_NAMES.MAJOR_SEVENTH,
    INTERVAL_NAMES.PERFECT_OCTAVE
  ],
  [GREEK_MODES_NAMES.MIXOLYDIAN]: [
    INTERVAL_NAMES.MAJOR_SECOND,
    INTERVAL_NAMES.MAJOR_THIRD,
    INTERVAL_NAMES.PERFECT_FOURTH,
    INTERVAL_NAMES.PERFECT_FIFTH,
    INTERVAL_NAMES.MAJOR_SIXTH,
    INTERVAL_NAMES.MINOR_SEVENTH,
    INTERVAL_NAMES.PERFECT_OCTAVE
  ],
  [GREEK_MODES_NAMES.AEOLIAN]: [
    INTERVAL_NAMES.MAJOR_SECOND,
    INTERVAL_NAMES.MINOR_THIRD,
    INTERVAL_NAMES.PERFECT_FOURTH,
    INTERVAL_NAMES.PERFECT_FIFTH,
    INTERVAL_NAMES.MINOR_SIXTH,
    INTERVAL_NAMES.MINOR_SEVENTH,
    INTERVAL_NAMES.PERFECT_OCTAVE
  ],
  [GREEK_MODES_NAMES.LOCRIAN]: [
    INTERVAL_NAMES.MINOR_SECOND,
    INTERVAL_NAMES.MINOR_THIRD,
    INTERVAL_NAMES.PERFECT_FOURTH,
    INTERVAL_NAMES.DIMINISHED_FIFTH,
    INTERVAL_NAMES.MINOR_SIXTH,
    INTERVAL_NAMES.MINOR_SEVENTH,
    INTERVAL_NAMES.PERFECT_OCTAVE
  ]
}

const SCREEN_SIZES = {
  SMALL: 'small',
  REGULAR: 'regular',
  LARGE: 'large'
}

const OCTAVES_BY_SCREEN_SIZE = {
  [SCREEN_SIZES.SMALL]: [3,4],
  [SCREEN_SIZES.REGULAR]: [3,4,5],
  [SCREEN_SIZES.LARGE]: [3,4,5]
}

const LIGHT_MODE_KEYS = { // If you change this change also scss file
  LIGHT: 'light',
  DARK: 'dark'
}

const COLORS = { // If you change this change also scss file and color picker component
  RED: 'red',
  BLUE: 'blue',
  GREEN: 'green'
}

module.exports = {
  CHORDS,
  MAIN_CHORDS_NAMES,
  EXTRA_CHORDS_NAMES,
  SEVENTH_EXTRA_INTERVAL,
  SEVENTH_NAMES,
  CHORD_STATES_NAMES,
  CHORD_STATES_ROTATIONS,
  KEY_TYPES,
  INTERVALS,
  INTERVAL_TYPES,
  INTERVAL_NAMES,
  MODE_NAMES,
  NOTATION_NAMES,
  NOTES_BY_NOTATION_NAME,
  NOTES_LIST,
  OCTAVE_KEYS,
  SCALES_NAMES,
  SCALES,
  GREEK_MODES_NAMES,
  GREEK_MODES,
  SCREEN_SIZES,
  OCTAVES_BY_SCREEN_SIZE,
  POPUP_STATES,
  LIGHT_MODE_KEYS,
  COLORS,
  HAS_MORE_OPTIONS_BY_MODE
}