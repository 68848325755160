const {Howl, Howler} = require('howler')

const _ = require('lodash')

const { OCTAVE_KEYS, OCTAVES_BY_SCREEN_SIZE, SCREEN_SIZES } = require('./constants')

const sounds = {}
Howler.html5PoolSize = 100

const loadAudios = () => {
  _.forEach(OCTAVES_BY_SCREEN_SIZE[SCREEN_SIZES.LARGE], (octaveNumber) => {
    return _.forEach(OCTAVE_KEYS, key => {
      const parsedNote = _.replace(_.first(key.notes), '#', 'Sharp')
      const audioKey = `${parsedNote}-${octaveNumber}`
      sounds[audioKey] = new Howl({
        src: [require(`../assets/sounds/${audioKey}.mp3`)]
      })
    })
  })
}

const playKey = (keyIndex, octaveNumber) => {
  if(!_.includes(OCTAVES_BY_SCREEN_SIZE[SCREEN_SIZES.LARGE], octaveNumber)) return
  const note = _.first(OCTAVE_KEYS[keyIndex].notes)
  const parsedNote = _.replace(note, '#', 'Sharp')
  const audioKey = `${parsedNote}-${octaveNumber}`
  if (sounds[audioKey]) {
    sounds[audioKey].play()
  }
}

const setVolume = (newVolume) => {
  Howler.volume(parseFloat(newVolume))
  return parseFloat(newVolume)
}

module.exports = {
  loadAudios,
  playKey,
  setVolume
}