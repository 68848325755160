<template>
  <div class="mode-section__row">
    <div class="mode-section__column">
      <div class="mode-section__title">
        <span>{{ $t('selectMode') }}</span>
        <div
          class="mode-section__question-button"
          @click="setPopupState(POPUP_STATES.INFO)"
        >
          <font-awesome-icon
            :icon="['fas', 'question']"
          />
        </div>
      </div>
      <Selector
        :options="modes"
        @select="changeSelectedMode"
      />
    </div>
    <div class="mode-section__column">
      <div class="mode-section__title">
        <span>{{ mainOptionTitle }}</span>
        <div
          class="mode-section__question-button"
          @click="setPopupState(selectedMode)"
        >
          <font-awesome-icon
            :icon="['fas', 'question']"
          />
        </div>
      </div>
      <Selector
        :options="mainOptions"
        @select="selectMainOption"
      />
    </div>
  </div>
  <div
    v-if="hasMoreOptions"
    class="mode-section__toggle-more-options"
    @click="toggleMoreOptions()"
  >
    <font-awesome-icon
      class="mode-section__toggle-more-options__icon"
      :class="{'mode-section__toggle-more-options__icon--rotated': areMoreOptionsDispayed}"
      :icon="['fas', 'chevron-right']"
      @click="$emit('close')"
    />
    <span>{{ $t('moreOptions') }}</span>
  </div>
</template>

<script>
import Selector from '../generic/Selector.vue'

import { mapState, mapActions } from 'pinia'
import { useStore } from '../../store'

const _ = require('lodash')

const { INTERVAL_NAMES, MAIN_CHORDS_NAMES, MODE_NAMES, SCALES_NAMES, GREEK_MODES_NAMES, POPUP_STATES, HAS_MORE_OPTIONS_BY_MODE } = require('../../utils/constants')

export default {
  name: 'ModeSection',
  components: {
    Selector
  },
  created() {
    this.MODE_NAMES = MODE_NAMES
    this.POPUP_STATES = POPUP_STATES
  },

  computed: {
    ...mapState(useStore, ['selectedMode']),
    ...mapState(useStore, ['selectedInterval']),
    ...mapState(useStore, ['selectedChord']),
    ...mapState(useStore, ['selectedScale']),
    ...mapState(useStore, ['selectedGreekMode']),
    ...mapState(useStore, ['areMoreOptionsDispayed']),

    hasMoreOptions () {
      return !!HAS_MORE_OPTIONS_BY_MODE[this.selectedMode]
    },

    modes () {
      return _.map(MODE_NAMES, mode => {
        return {
          label: this.$t(`modeNames.${mode}`),
          value: mode,
          checked: this.selectedMode === mode
        }
      })
    },

    intervals () {
      return _.map(INTERVAL_NAMES, intervalName => {
        return {
          label: this.$t(`intervalNames.${intervalName}`),
          value: intervalName,
          checked: this.selectedInterval === intervalName
        }
      })
    },

    chords () {
      return _.map(MAIN_CHORDS_NAMES, chordName => {
        return {
          label: this.$t(`mainChords.${chordName}`),
          value: chordName,
          checked: this.selectedChord === chordName
        }
      })
    },

    scales () {
      return _.map(SCALES_NAMES, scale => {
        return {
          label: this.$t(`scaleNames.${scale}`),
          value: scale,
          checked: this.selectedScale === scale
        }
      })
    },

    greekModes () {
      return _.map(GREEK_MODES_NAMES, greekMode => {
        return {
          label: this.$t(`greekModeNames.${greekMode}`),
          value: greekMode,
          checked: this.selectedGreekMode === greekMode
        }
      })
    },

    mainOptionTitle () {
      switch (this.selectedMode) {
        case MODE_NAMES.INTERVAL:
          return this.$t('interval')
        case MODE_NAMES.CHORD:
          return this.$t('selectChord')
        case MODE_NAMES.SCALE:
          return this.$t('scale')
        case MODE_NAMES.GREEK_MODE:
          return this.$t('greekMode')
        default:
          return ''
      }
    },

    mainOptions () {
      switch (this.selectedMode) {
        case MODE_NAMES.INTERVAL:
          return this.intervals
        case MODE_NAMES.CHORD:
          return this.chords
        case MODE_NAMES.SCALE:
          return this.scales
        case MODE_NAMES.GREEK_MODE:
          return this.greekModes
        default:
          console.warn('Wrong mode')
          return []
      }
    }
  },

  methods: {
    ...mapActions(useStore, ['changeSelectedMode']),
    ...mapActions(useStore, ['selectMainOption']),
    ...mapActions(useStore, ['toggleMoreOptions']),
    ...mapActions(useStore, ['setPopupState'])
  }
}
</script>

<style lang="scss">
.mode-section__title {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1.5px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.mode-section__question-button{
  padding: 3px;
  background-color: var(--button_color);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  &:hover{
    background-color: var(--button_color__hover);
  }
  &:active{
    background-color: var(--button_color__pressed);
  }
}

.mode-section__row {
  display: flex;
  gap: 60px;
}

.mode-section__column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mode-section__toggle-more-options {
  margin: 20px 0 10px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  color: var(--text_color);
  align-items: center;
  font-weight: bold;
  &:hover, :active{
    text-decoration: underline;
  }
}

.mode-section__toggle-more-options__icon {
  transform: rotate(0);
  transition: transform 0.25s;
}

.mode-section__toggle-more-options__icon--rotated {
  transform: rotate(90deg);
}
</style>
