import { defineStore } from 'pinia'

const _ = require('lodash')

const {
  MAIN_CHORDS_NAMES,
  CHORD_STATES_NAMES,
  SEVENTH_NAMES,
  INTERVAL_NAMES,
  INTERVAL_TYPES,
  MODE_NAMES,
  SCALES_NAMES,
  GREEK_MODES_NAMES,
  NOTATION_NAMES,
  OCTAVES_BY_SCREEN_SIZE,
  SCREEN_SIZES,
  LIGHT_MODE_KEYS,
  COLORS
} = require('./utils/constants')

const { playKey, setVolume } = require('./utils/sound-utils')
const { setStavesNotes } = require('./utils/stave-utils')
const { calculateChordInversion, calculateNotesFromChord, calculateNotesFromInterval, calculateNotesFromScale } = require('./utils/chords-utils')

export const useStore = defineStore('main', {
  state: () => {
    return {
      isPlayModeEnabled: !!localStorage.getItem('isPlayModeEnabled') || false,
      octavesToShow: OCTAVES_BY_SCREEN_SIZE[SCREEN_SIZES.LARGE],
      areMoreOptionsDispayed: false,
      popupState: null,
      selectedChord: MAIN_CHORDS_NAMES.MAJOR_TRIAD,
      selectedSeventh: SEVENTH_NAMES.NO_SEVENTH,
      selectedChordState: CHORD_STATES_NAMES.NATURAL_STATE,
      selectedInterval: INTERVAL_NAMES.MINOR_SECOND,
      selectedIntervalType: INTERVAL_TYPES.UPWARD,
      selectedKey: null,
      selectedMode: MODE_NAMES.INTERVAL,
      selectedNotation: localStorage.getItem('selectedNotation') || NOTATION_NAMES.LATIN, // TODO: Check if it's valid value
      selectedScale: SCALES_NAMES.MAJOR,
      selectedGreekMode: GREEK_MODES_NAMES.IONIAN,
      selectedVolume: setVolume(parseFloat(localStorage.getItem('selectedVolume')) || 1),
      selectedColor: localStorage.getItem('selectedColor') || COLORS.GREEN,
      invertInterval: false,
      showNotesNames: !!localStorage.getItem('showNotesNames') || false,
      showStaff: !!localStorage.getItem('showStaff') || false,
      screenSize: SCREEN_SIZES.LARGE,
      isFullScreen: false,
      isMobile: false,
      isDarkModeEnabled: !_.isNil(localStorage.getItem('isDarkModeEnabled'))
        ? !!localStorage.getItem('isDarkModeEnabled')
        : true
    }
  },
  getters: {
    areNotesOutsideOctaves (state) {
      return _.some(state.selectedNotesAsObjects, noteObject => noteObject.octaveNumber > _.last(state.octavesToShow) || noteObject.octaveNumber < _.first(state.octavesToShow))
    },

    selectedNotesAsObjects: (state) => {
      if(!state.selectedKey) return []

      let notesAsObjects
      if (state.selectedMode === MODE_NAMES.INTERVAL) {
        notesAsObjects = calculateNotesFromInterval(state.selectedInterval, state.selectedIntervalType, state.selectedKey, state.invertInterval)
      } else if (state.selectedMode === MODE_NAMES.CHORD) {
        const preliminarNotes = calculateNotesFromChord(state.selectedChord, state.selectedKey, state.selectedSeventh)
        notesAsObjects = calculateChordInversion(preliminarNotes, state.selectedChordState)
      } else if (state.selectedMode === MODE_NAMES.SCALE) {
        notesAsObjects = calculateNotesFromScale(state.selectedScale, state.selectedKey)
      } else if (state.selectedMode === MODE_NAMES.GREEK_MODE) {
        notesAsObjects = calculateNotesFromScale(state.selectedGreekMode, state.selectedKey)
      }

      setStavesNotes(notesAsObjects, state.selectedMode === MODE_NAMES.CHORD)
      return notesAsObjects
    },
  },
  actions: {
    changePlayMode (event) {
      this.isPlayModeEnabled = event.target.checked
      localStorage.setItem('isPlayModeEnabled', this.isPlayModeEnabled ? 'true' : '')
    },
    
    changeSelectedMode (newMode) {
      if (newMode === MODE_NAMES.INTERVAL) {
        this.invertInterval = false
      } else if (newMode === MODE_NAMES.CHORD) {
        this.selectedChordState = CHORD_STATES_NAMES.NATURAL_STATE
      }
      this.selectedMode = newMode
    },

    changeSelectedNotation (event) {
      const newNotation = event.target.checked
        ? NOTATION_NAMES.ENGLISH
        : NOTATION_NAMES.LATIN
      this.selectedNotation = newNotation
      localStorage.setItem('selectedNotation', newNotation)
    },

    changeSelectedVolume (event) {
      const newVolume = event.target.value
      this.selectedVolume = newVolume
      setVolume(newVolume)
      localStorage.setItem('selectedVolume', newVolume)
    },

    changeShowNotesNames (event) {
      this.showNotesNames = event.target.checked
      localStorage.setItem('showNotesNames', this.showNotesNames ? 'true' : '')
    },

    changeShowStaff (event) {
      this.showStaff = _.get(event, 'target.checked', false)
      localStorage.setItem('showStaff', this.showStaff ? 'true' : '')
    },

    play: _.throttle(function () {
      if (this.selectedMode === MODE_NAMES.CHORD) {
        this.playSelectedNotes()
      } else {
        this.playArpeggiated()
      }
    }, 1000),

    playSelectedNotes: _.throttle(function () {
      _.forEach(this.selectedNotesAsObjects, noteObject => playKey(noteObject.keyIndex, noteObject.octaveNumber))
    }, 1000),

    playArpeggiated: _.throttle(function () {
      _.forEach(this.selectedNotesAsObjects, (noteObject, index) => {
        setTimeout(() => {
          playKey(noteObject.keyIndex, noteObject.octaveNumber)
        }, index * 300)
      })
    }, 1000),

    selectMainOption (value) {
      switch (this.selectedMode) {
        case MODE_NAMES.INTERVAL:
          return this.selectInterval(value)
        case MODE_NAMES.CHORD:
          return this.selectChord(value)
        case MODE_NAMES.SCALE:
          return this.selectScale(value)
        case MODE_NAMES.GREEK_MODE:
          return this.selectGreekMode(value)
      }
    },

    selectChord (chord) {
      this.selectedChord = chord
    },

    selectChordState (chordState) {
      this.selectedChordState = chordState
    },

    selectSeventh (seventh) {
      this.selectedSeventh = seventh
      if (this.selectedSeventh === SEVENTH_NAMES.NO_SEVENTH && this.selectedChordState === CHORD_STATES_NAMES.THIRD_INVERSION) {
        this.selectChordState(CHORD_STATES_NAMES.NATURAL_STATE)
      }
    },

    selectKey (key) {
      if (this.selectedKey && this.selectedMode === MODE_NAMES.CHORD) {
        this.selectedChordState = CHORD_STATES_NAMES.NATURAL_STATE
      }
      this.selectedKey = key
    },
    
    selectInterval (interval) {
      this.selectedInterval = interval
    },

    selectIntervalType (intervalType) {
      this.selectedIntervalType = intervalType
    },

    selectScale (scale) {
      this.selectedScale = scale
    },

    selectGreekMode (greekMode) {
      this.selectedGreekMode = greekMode
    },

    setPopupState (newState) {
      this.popupState = newState
    },

    setShowInvertedInterval (isInverted) {
      this.invertInterval = isInverted
    },

    setScreenSize (width) {
      if (width > 1800 && this.screenSize !== SCREEN_SIZES.LARGE) {
        this.screenSize = SCREEN_SIZES.LARGE
        this.octavesToShow = OCTAVES_BY_SCREEN_SIZE[this.screenSize]
      }else if (width > 1100 && width <= 1800 && this.screenSize !== SCREEN_SIZES.REGULAR) {
        this.screenSize = SCREEN_SIZES.REGULAR
        this.octavesToShow = OCTAVES_BY_SCREEN_SIZE[this.screenSize]
      } else if (width <= 1100 && this.screenSize !== SCREEN_SIZES.SMALL) {
        this.screenSize = SCREEN_SIZES.SMALL
        this.octavesToShow = OCTAVES_BY_SCREEN_SIZE[this.screenSize]
      }
    },

    toggleMoreOptions () {
      this.areMoreOptionsDispayed = !this.areMoreOptionsDispayed
    },

    toggleFullScreen () {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen()
      } else if (document.exitFullscreen) {
        document.exitFullscreen()
      }
    },

    setFullScreen (value) {
      this.isFullScreen = value
    },

    toggleDarkMode (event) {
      this.isDarkModeEnabled = event.target.checked
      const themeKey = this.isDarkModeEnabled
        ? LIGHT_MODE_KEYS.DARK
        : LIGHT_MODE_KEYS.LIGHT
      document.body.setAttribute('theme', `${this.selectedColor}-${themeKey}`)
      localStorage.setItem('isDarkModeEnabled', this.isDarkModeEnabled ? 'true' : '')
    },

    changeSelectedColor (color) {
      const themeKey = this.isDarkModeEnabled
        ? LIGHT_MODE_KEYS.DARK
        : LIGHT_MODE_KEYS.LIGHT
      this.selectedColor = color
      document.body.setAttribute('theme', `${color}-${themeKey}`)
      localStorage.setItem('selectedColor', color)
    },

    setIsMobile (isMobile) {
      this.isMobile = !!isMobile
    },

    removeSelection () {
      this.selectedKey = null
    }
  },
})