<template>
  <div class="interval-section__row">
    <div class="interval-section__column">
      <div class="interval-section__subtitle">
        {{ $t('intervalOrder') }}
      </div>
      <Selector
        :options="intervalTypes"
        @select="selectIntervalType"
      />
    </div>
    <div class="interval-section__column">
      <div class="interval-section__subtitle">
        <span>{{$t('invertInterval')}}</span>
        <div
          class="interval-section__question-button"
          @click="setPopupState(POPUP_STATES.INVERT_INTERVAL)"
        >
          <font-awesome-icon
            :icon="['fas', 'question']"
          />
        </div>
      </div>
      <input-switch
        class="interval-section__invert-interval"
        @input="setShowInvertedInterval($event.target.checked)"
      />
    </div>
  </div>
</template>

<script>
import Selector from '../generic/Selector.vue'

import { mapState, mapActions } from 'pinia'
import { useStore } from '../../store'

import InputSwitch from '../generic/InputSwitch.vue'

const _ = require('lodash')

const { INTERVAL_TYPES, POPUP_STATES } = require('../../utils/constants')

export default {
  name: 'IntervalSection',
  components: {
    InputSwitch,
    Selector
  },

  created() {
    this.POPUP_STATES = POPUP_STATES
  },

  computed: {
    ...mapState(useStore, ['selectedIntervalType']),

    intervalTypes () {
      return _.map(INTERVAL_TYPES, intervalType => {
        return {
          label: this.$t(`intervalTypes.${intervalType}`),
          value: intervalType,
          checked: this.selectedIntervalType === intervalType
        }
      })
    },
  },

  methods: {
    ...mapActions(useStore, ['selectIntervalType']),
    ...mapActions(useStore, ['setShowInvertedInterval']),
    ...mapActions(useStore, ['setPopupState'])
  }
}
</script>

<style lang="scss">
.interval-section__subtitle {
  margin: 5px 0;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.5px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.interval-section__row{
  display: flex;
  gap: 60px;
  width: 100%;
}

.interval-section__column {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 0px;
  .input-switch__label{
    margin-top: 5px;
  }
}

.interval-section__question-button{
  display: inline-block;
  padding: 3px;
  background-color: var(--button_color);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  &:hover{
    background-color: var(--button_color__hover);
  }
  &:active{
    background-color: var(--button_color__pressed);
  }
}
</style>
