<template>
  <div
    v-if="areNotesOutsideOctaves"
    class="info-section__warning-text info-section__warning-text--orange"
  > 
    {{ $t('notesOutWarning') }}
  </div>

  <div
    v-if="isPlayModeEnabled"
    class="info-section__warning-text"
  > 
    {{ $t('freeModeWarning') }}
  </div>

  <div
    v-if="this.selectedKey"
    class="info-section__main-content"
  >
    <span>{{ displayedText }}</span>
    <span class="info-section__buttons">
      <div
        class="info-section__button"
        @click="play"
      >
        {{ $t('play') }}
      </div>
      <div
        v-if="selectedMode === MODE_NAMES.CHORD"
        class="info-section__button"
        @click="playArpeggiated"
      >
        {{ $t('playArpeggiated') }}
      </div>
      <div
        class="info-section__button"
        @click="removeSelection"
      >
        <font-awesome-icon
          :icon="['fas', 'trash-can']"
        />
      </div>
    </span>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useStore } from '../../store'

const { translateNote, translateNotes } = require('../../utils/translation-utils')
const { getInvertedInterval } = require('../../utils/chords-utils')

const { MODE_NAMES, SEVENTH_NAMES, INTERVALS } = require('../../utils/constants')
const _ = require('lodash')

export default {
  name: 'InfoSection',
  computed: {
    ...mapState(useStore, ['areNotesOutsideOctaves']),
    ...mapState(useStore, ['selectedKey']),
    ...mapState(useStore, ['selectedMode']),
    ...mapState(useStore, ['selectedInterval']),
    ...mapState(useStore, ['selectedIntervalType']),
    ...mapState(useStore, ['selectedChord']),
    ...mapState(useStore, ['selectedChordState']),
    ...mapState(useStore, ['selectedSeventh']),
    ...mapState(useStore, ['selectedScale']),
    ...mapState(useStore, ['selectedGreekMode']),
    ...mapState(useStore, ['selectedNotesAsObjects']),
    ...mapState(useStore, ['selectedNotation']),
    ...mapState(useStore, ['invertInterval']),
    ...mapState(useStore, ['isPlayModeEnabled']),

    displayedText () {
      if (!this.selectedKey) return ''

      let initialText = this.$t(`modeNames.${this.selectedMode}`)
      if (this.selectedMode === MODE_NAMES.INTERVAL) {
        let intervalToUse
        if (this.invertInterval){
          intervalToUse = getInvertedInterval(this.selectedInterval)
          initialText = this.$t('invertedInterval')
        } else {
          intervalToUse = {
            intervalName: this.selectedInterval,
            intervalType: this.selectedIntervalType,
            semitones: INTERVALS[this.selectedInterval].semitones
          }
        }
        const tonesNumber = _.floor(intervalToUse.semitones / 2)
        const semitonesNumber = intervalToUse.semitones % 2
        let tonesFullTranslation = ''

        if(tonesNumber) {
          const tonesTranslation = this.$tc('tones', tonesNumber, {
            tones: tonesNumber
          })
          tonesFullTranslation = tonesTranslation
          if (semitonesNumber) {
            tonesFullTranslation += this.$t('and')
          }
        }

        if (semitonesNumber) {
          const semitonesTranslation = this.$tc('semitones', semitonesNumber, {
            semitones: semitonesNumber
          })
          tonesFullTranslation += semitonesTranslation
        }

        const noteToUse = this.invertInterval
          ? _.first(this.selectedNotesAsObjects).note
          : this.selectedKey.note
        return this.$t('intervalInfo', {
          intervalTitle: initialText,
          interval: this.$t(`intervalNames.${intervalToUse.intervalName}`),
          tones: tonesFullTranslation,
          intervalType: _.toLower(this.$t(`intervalTypes.${intervalToUse.intervalType}`)),
          note: translateNote(noteToUse, this.selectedNotation),
          notes: translateNotes(this.selectedNotesAsObjects, this.selectedNotation, this.$t('and'))
        })
      } else if (this.selectedMode === MODE_NAMES.CHORD) {
        const chordName = this.selectedSeventh === SEVENTH_NAMES.NO_SEVENTH
          ? this.$t(`mainChords.${this.selectedChord}`)
          : this.$t(`fourNoteChordsComplete.${this.selectedSeventh}`, {
            chordName: this.$t(`fourNoteChords.${this.selectedChord}`)
          })
        return this.$t('chordInfo', {
          chordTitle: initialText,
          chord: chordName,
          chordState: _.toLower(this.$t(`chordStates.${this.selectedChordState}`)),
          note: translateNote(this.selectedKey.note, this.selectedNotation),
          notes: translateNotes(this.selectedNotesAsObjects, this.selectedNotation, this.$t('and'))
        })
      } else if (this.selectedMode === MODE_NAMES.SCALE) {
        return this.$t('scaleInfo', {
          scaleTitle: initialText,
          scale: this.$t(`scaleNames.${this.selectedScale}`),
          note: translateNote(this.selectedKey.note, this.selectedNotation),
          notes: translateNotes(this.selectedNotesAsObjects, this.selectedNotation, this.$t('and'))
        })
      } else if (this.selectedMode === MODE_NAMES.GREEK_MODE) {
        return this.$t('scaleInfo', {
          scaleTitle: initialText,
          scale: this.$t(`greekModeNames.${this.selectedGreekMode}`),
          note: translateNote(this.selectedKey.note, this.selectedNotation),
          notes: translateNotes(this.selectedNotesAsObjects, this.selectedNotation, this.$t('and'))
        })
      }
      return console.warn('This should not happen')
    }
  },

  beforeMount () {
    this.MODE_NAMES = MODE_NAMES
  },

  methods: {
    ...mapActions(useStore, ['play']),
    ...mapActions(useStore, ['playArpeggiated']),
    ...mapActions(useStore, ['selectChord']),
    ...mapActions(useStore, ['selectChordState']),
    ...mapActions(useStore, ['removeSelection'])
  }
}
</script>

<style lang="scss" scoped>
.info-section__main-content {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  font-size: 17px;
  line-height: 20px;
  user-select: text;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.info-section__buttons {
  display: flex;
  gap: 10px;
}

.info-section__button{
  display: inline-block;
  user-select: none;
  outline: none;
  padding: 5px 10px;
  color: white;
  border-radius: 5px;
  background-color: var(--button_color);
  cursor: pointer;
  &:hover {
    background-color: var(--button_color__hover);
  }
  &:active {
    background-color: var(--button_color__pressed);
  }
}

.info-section__warning-text{
  font-size: 18px;
  color: #3AB4F2;
  margin-bottom: 10px;
  &--orange{
    color: orange;
  }
}
</style>
