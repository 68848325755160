const { Vex, StaveNote, Voice, Formatter, Accidental, GhostNote } = require('vexflow')
const _ = require('lodash')

const { NOTATION_NAMES, NOTES_BY_NOTATION_NAME, NOTES_LIST } = require('./constants')

let bassStave
let trebleStave
let context

function initStaves () {
  const { Renderer, Stave } = Vex.Flow

  // Create an SVG renderer and attach it to the DIV element named "boo".
  const div = document.getElementById('staves-container')
  const renderer = new Renderer(div, Renderer.Backends.SVG)
  
  // Configure the rendering context.
  renderer.resize(500, 210)
  context = renderer.getContext()

  bassStave = new Stave(0, 95, 500)

  // Add a clef and time signature.
  bassStave.addClef("bass")
  // Connect it to the rendering context and draw!
  bassStave.setContext(context).draw()

  trebleStave = new Stave(0, 5, 500)

  // Add a clef and time signature.
  trebleStave.addClef("treble")

  // Connect it to the rendering context and draw!
  trebleStave.setContext(context).draw()
}

function setStavesNotes (notesAsObjects, isChord) {
  const staff = document.getElementById('staves-container')
  while (_.size(staff.childNodes) > 1) {
    staff.removeChild(staff.lastChild)
  }
  initStaves()

  const [bassNotes, trebleNotes] = _.partition(notesAsObjects, noteObject => noteObject.octaveNumber < 4)

  const bassVexNotes = getVexNotes(bassNotes, 'bass', isChord)
  const trebleVexNotes = getVexNotes(trebleNotes, 'treble', isChord)

  const totalSize = _.size(bassVexNotes) + _.size(trebleVexNotes)
  const numberOfBeats = isChord
    ? 1
    : totalSize

  const bassVoice = new Voice({ num_beats: numberOfBeats, beat_value: 1 })
  const bassTickableNotes = getTickableNotes(bassVexNotes, bassNotes, totalSize, isChord, true)
  bassVoice.addTickables(bassTickableNotes)

  const trebleVoice = new Voice({ num_beats: numberOfBeats, beat_value: 1 })
  const trebleTickableNotes = getTickableNotes(trebleVexNotes, trebleNotes, totalSize, isChord, false)
  trebleVoice.addTickables(trebleTickableNotes)
  
  new Formatter().joinVoices([trebleVoice, bassVoice]).format([trebleVoice, bassVoice], 450)

  // Render voices
  if (bassVoice) {
    bassVoice.draw(context, bassStave)
  }
  if (trebleVoice) {
    trebleVoice.draw(context, trebleStave)
  }
}

function getVexNotes (notes, clef, isChord) {
  if (!_.size(notes)) return []

  const vexNotes = _.map(notes, noteObject => {
    const naturalNote = _.find(NOTES_LIST, noteObject.note)
      ? naturalNote
      : noteObject.note.replaceAll('#', '').replaceAll('b', '').replaceAll('ˣ', '')
    const modifiersSize = _.size(noteObject.note) - _.size(naturalNote)
    const translatedNote = NOTES_BY_NOTATION_NAME[NOTATION_NAMES.ENGLISH][naturalNote]
    const octaveNumber = getOctaveNumber(noteObject)
    const noteAndOctave = translatedNote + '/' + octaveNumber
    const accidental = modifiersSize > 0
      ? _.replace(noteObject.note.slice(-modifiersSize), 'ˣ', '##')
      : null

    return {
      noteAndOctave,
      accidental
    }
  })

  return isChord
    ? getVexChordNote(vexNotes, clef)
    : getVexIndividualNotes(vexNotes, clef)
}

function getVexIndividualNotes (vextNotes, clef) {
  return _.map(vextNotes, vexNoteObject => {
    const note = new StaveNote({ clef, keys: [vexNoteObject.noteAndOctave], duration: "w" })
    
    return vexNoteObject.accidental
      ? note.addModifier(new Accidental(vexNoteObject.accidental))
      : note
  })
}

function getVexChordNote (vextNotes, clef) {
  const vexChord = new StaveNote({ clef, keys: _.map(vextNotes, 'noteAndOctave'), duration: "w" })
  _.forEach(vextNotes, (vexNoteObject, index) => {
    if (!vexNoteObject.accidental) return

    vexChord.addModifier(new Accidental(vexNoteObject.accidental), index)
  })

  return [vexChord]
}

function getTickableNotes (vexNotes, notes, totalSize, isChord, isBass) {
  if (!_.size(notes)) {
    return isChord
      ? getGhostNotes(1)
      : getGhostNotes(totalSize)
  } 
  
  let tickableNotes
  if (isChord) {
    tickableNotes = vexNotes
  } else {
    const ghostNotes = getGhostNotes(totalSize - _.size(notes))
    tickableNotes = isBass
      ? [...vexNotes, ...ghostNotes]
      : [...ghostNotes, ...vexNotes]
  }
  return tickableNotes
}

function getGhostNotes (numberOfNotes) {
  return _.times(numberOfNotes, () => new GhostNote({ duration: "w" }))
}

function getOctaveNumber (noteObject) {
  switch (noteObject.note) {
    case 'Laˣ':
    case 'Si#':
    case 'Siˣ':
      return noteObject.octaveNumber - 1
    case 'Dob':
    case 'Dobb':
    case 'Rebb':
      return noteObject.octaveNumber + 1
    default:
      return noteObject.octaveNumber
  }
}

module.exports = {
  initStaves,
  setStavesNotes
}