<template>
  <div class="selector">
    <template v-if="screenSize === SCREEN_SIZES.SMALL && isMobile">
      <select
        class="selector-html"
        @change="onSelectOptions($event.target.value)"
      >
        <option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          :selected="option.checked"
          class="selector-html__option"
        >
          {{ option.label }}
        </option>
      </select>    
    </template>
    <template v-else>
      <Multiselect
        ref="multiselect"
        class="selector-multiselect"
        v-model="selectedOption"
        :options="options"
        :can-deselect="false"
        :can-clear="false"
        @click="onMultiselectKeydown"
        @select="onSelectOptions"
      />
    </template>

  </div>
</template>
<script>
import Multiselect from '@vueform/multiselect'

import { mapState } from 'pinia'
import { useStore } from '../../store'

const _ = require('lodash')

const { SCREEN_SIZES } = require('../../utils/constants')

export default {
  name: 'SegmentedControl',
  components: {
    Multiselect
  },
  props: {
    options: {
      type: Array,
      required: true
    }
  },

  data: function() {
    return {
      selectedOption: ''
    }
  },

  computed: {
    ...mapState(useStore, ['screenSize']),
    ...mapState(useStore, ['isMobile'])
  },

  watch: {
    options () {
      this.selectedOption = _.get(_.find(this.options, { checked: true }), 'value')
    }
  },

  beforeMount () {
    this.SCREEN_SIZES = SCREEN_SIZES
  },

  mounted() {
    this.selectedOption = _.get(_.find(this.options, { checked: true }), 'value')
  },

  methods: {
    onSelectOptions(value){
      this.$emit('select', value)
    },

    onMultiselectKeydown (event) {
      if (event.target.classList.contains('is-selected') || !!event.target.closest('.multiselect-option.is-selected')) {
        const multiselectRef = this.$refs.multiselect
        if (multiselectRef) {
          this.$refs.multiselect.close()
        }
      }
    }
  }
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss">
.selector-html{
  background-color: transparent;
  border: 2px solid;
  border-color: var(--button_color);
  border-radius: 5px;
  color: var(--text_color);
  padding: 5px;
  font-size: 14px;
  outline: none;
  width: 180px;
  .selector-html__option{
    background-color: var(--background_color_secondary);
    color: var(--text_color);
    font-size: 14px;
  }
}

.selector-multiselect {
  width: 205px;
  border: 2px solid var(--button_color) !important;
  color: var(--text_color) !important;
  padding: 0;
  font-size: 14px;
  outline: none;
  box-shadow: none !important;
  background: var(--background_color);
  min-height: 35px;
  .multiselect-wrapper {
    height: 30px;
    min-height: 20px;
  }
  .multiselect-dropdown {
    background: var(--background_color);
    overflow-y: auto;
    border: 2px solid var(--button_color);
    left: calc(var(--ms-border-width, 1px)*-2);
    right: calc(var(--ms-border-width, 1px)*-2);
    max-height: 400px;
  }
  .multiselect-option{
    background: var(--background_color);
    color: var(--text_color);
    font-size: 14px;
    &.is-selected {
      background: var(--background_color);
      color: var(--text_color);
    }
    &.is-pointed {
      background: var(--button_color);
      color: white;
    }
  }
  .multiselect-single-label {
    padding-right: 15px;
  }
}
</style>
