<template>
  <div class="chord-section__row">
    <div class="chord-section__column">
      <div class="chord-section__subtitle">
        {{ $t('seventh') }}
      </div>
      <Selector
        :options="sevenths"
        @select="selectSeventh"
      />
    </div>
    <div class="chord-section__column">
      <div class="chord-section__subtitle">
        <span>{{ $t('chordState') }}</span>
        <div
          class="chord-section__question-button"
          @click="setPopupState(POPUP_STATES.CHORD_STATE)"
        >
          <font-awesome-icon
            :icon="['fas', 'question']"
          />
        </div>
      </div>
      <Selector
        :options="chordStates"
        @select="selectChordState"
      />
    </div>
  </div>
</template>

<script>
import Selector from '../generic/Selector.vue'

import { mapState, mapActions } from 'pinia'
import { useStore } from '../../store'

const _ = require('lodash')

const { SEVENTH_NAMES, CHORD_STATES_NAMES, POPUP_STATES } = require('../../utils/constants')

export default {
  name: 'ChordSection',
  components: {
    Selector
  },

  computed: {
    ...mapState(useStore, ['selectedChord']),
    ...mapState(useStore, ['selectedChordState']),
    ...mapState(useStore, ['selectedSeventh']),

    sevenths () {
      return _.map(SEVENTH_NAMES, seventhName => {
        return {
          label: this.$t(`seventhNames.${seventhName}`),
          value: seventhName,
          checked: this.selectedSeventh === seventhName
        }
      })
    },

    availableChordStates () {
      return this.selectedSeventh === SEVENTH_NAMES.NO_SEVENTH
        ? _.omit(CHORD_STATES_NAMES, 'THIRD_INVERSION')
        : CHORD_STATES_NAMES
    },

    chordStates () {
      return _.map(this.availableChordStates, chordState => {
        return {
          label: this.$t(`chordStates.${chordState}`),
          value: chordState,
          checked: this.selectedChordState === chordState
        }
      })
    }
  },

  created() {
    this.POPUP_STATES = POPUP_STATES
  },

  methods: {
    ...mapActions(useStore, ['selectChordState']),
    ...mapActions(useStore, ['selectSeventh']),
    ...mapActions(useStore, ['setPopupState'])
  }
}
</script>

<style scoped>
.chord-section__subtitle {
  margin: 5px 0;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.5px;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 28px;
}

.chord-section__question-button{
  display: inline-block;
  padding: 3px;
  background-color: var(--button_color);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  &:hover{
    background-color: var(--button_color__hover);
  }
  &:active{
    background-color: var(--button_color__pressed);
  }
}

.chord-section__row{
  display: flex;
  gap: 60px;
}

.chord-section__column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
