const { NOTATION_NAMES, NOTES_LIST, NOTES_BY_NOTATION_NAME } = require('./constants')
const _ = require('lodash')

const translateNote = (noteToTranslate, notationToUse) => {
  if(notationToUse === NOTATION_NAMES.LATIN) return replaceB(noteToTranslate)

  const mainLatinNote = _.find(NOTES_LIST, mainNote => _.includes(noteToTranslate, mainNote))
  return replaceB(_.replace(noteToTranslate, mainLatinNote, NOTES_BY_NOTATION_NAME[NOTATION_NAMES.ENGLISH][mainLatinNote]))
}

const translateNotes = (notesToTranslate, notationToUse, andText) => {
  return _.join(_.map(notesToTranslate, noteObject => translateNote(noteObject.note, notationToUse)), ', ').replace(/, ([^, ]*)$/, andText + '$1')
}

const replaceB = (stringToCheck) => {
  return _.replace(stringToCheck, new RegExp('b','g'), '♭')
}

module.exports = {
  translateNote,
  translateNotes
}