const { 
  NOTES_LIST,
  INTERVALS,
  INTERVAL_NAMES,
  INTERVAL_TYPES,
  OCTAVE_KEYS,
  SCALES,
  GREEK_MODES,
  CHORDS,
  CHORD_STATES_ROTATIONS,
  SEVENTH_EXTRA_INTERVAL
} = require('./constants')
const _ = require('lodash')

const calculateNoteFromInterval = function (intervalName, intervalType, keyObject) {
  const interval = INTERVALS[intervalName]

  if (interval.semitones === 12){
    return {
      keyIndex: keyObject.keyIndex,
      note: keyObject.note,
      octaveNumber: intervalType === INTERVAL_TYPES.UPWARD
        ? keyObject.octaveNumber + 1
        : keyObject.octaveNumber - 1
    }
  } 

  return intervalType === INTERVAL_TYPES.UPWARD
    ? calculateUpwardsInterval(interval, keyObject)
    : calculateDownwardsInterval(interval, keyObject)

  function calculateUpwardsInterval(interval, keyObject) {
    const unsecureKeyIndex = keyObject.keyIndex + interval.semitones
    const keyIndex = unsecureKeyIndex % 12
    const octaveToReturn = unsecureKeyIndex >= 12
      ? keyObject.octaveNumber + 1
      : keyObject.octaveNumber
    const endKey = OCTAVE_KEYS[keyIndex]
    const indexInsideKey = getIndexInsideKeyByNotesDifference(keyObject.note, intervalType, interval.notesInterval, endKey.notes)

    return {
      keyIndex,
      note: endKey.notes[indexInsideKey],
      octaveNumber: octaveToReturn
    }
  }

  function calculateDownwardsInterval(interval, keyObject) {
    const unsecureKeyIndex = keyObject.keyIndex - interval.semitones
    const keyIndex = unsecureKeyIndex < 0
      ? 12 - Math.abs(unsecureKeyIndex)
      : unsecureKeyIndex
    const octaveToReturn = unsecureKeyIndex < 0
      ? keyObject.octaveNumber - 1
      : keyObject.octaveNumber

    const endKey = OCTAVE_KEYS[keyIndex]
    const indexInsideKey = getIndexInsideKeyByNotesDifference(keyObject.note, intervalType, interval.notesInterval, endKey.notes)

    return {
      keyIndex,
      note: endKey.notes[indexInsideKey],
      octaveNumber: octaveToReturn
    }
  }
}

const getInvertedInterval = function (intervalName) {
  const interval = INTERVALS[intervalName]
  if (intervalName === INTERVAL_NAMES.PERFECT_OCTAVE) {
    return {
      intervalName,
      intervalType: INTERVAL_TYPES.UPWARD,
      semitones: interval.semitones
    }
  }

  const semitonesToGet = 12 - interval.semitones
  const notesDifferenceToGet = 7 - interval.notesInterval
  const foundInterval = _.findKey(INTERVALS, intervalI => {
    return intervalI.semitones === semitonesToGet && intervalI.notesInterval === notesDifferenceToGet
  })
  return {
    intervalName: foundInterval,
    intervalType: INTERVAL_TYPES.UPWARD,
    semitones: INTERVALS[foundInterval].semitones
  }
}

const calculateNoteDifference = function(initialNote, endNote, intervalType) {
  const initialNoteIndex = _.findIndex(NOTES_LIST, note => initialNote.includes(note))
  const endNoteIndex = _.findIndex(NOTES_LIST, note => endNote.includes(note))

  if (intervalType === INTERVAL_TYPES.UPWARD) {
    return endNoteIndex > initialNoteIndex
      ? endNoteIndex - initialNoteIndex
      : _.size(NOTES_LIST) - initialNoteIndex + endNoteIndex
  } else {
    return initialNoteIndex > endNoteIndex
      ? initialNoteIndex - endNoteIndex
      : _.size(NOTES_LIST) - endNoteIndex + initialNoteIndex
  }
}

const getIndexInsideKeyByNotesDifference = function(initialNote, intervalType, noteDifference, notesInsideKey) {
  return _.findIndex(notesInsideKey, note => calculateNoteDifference(initialNote, note, intervalType) === noteDifference)
}

const calculateNotesFromInterval = function (intervalName, intervalType, keyObject, invertInterval) {
  let result = []
  if (invertInterval && intervalName !== INTERVAL_NAMES.PERFECT_OCTAVE) {
    const noteFromOriginalInterval = calculateNoteFromInterval(intervalName, intervalType, keyObject)
    if (intervalType === INTERVAL_TYPES.UPWARD) {
      result.push(noteFromOriginalInterval)
      result.push(calculateNoteFromInterval(INTERVAL_NAMES.PERFECT_OCTAVE, INTERVAL_TYPES.UPWARD, keyObject))
    } else {
      result.push(keyObject)
      result.push(calculateNoteFromInterval(INTERVAL_NAMES.PERFECT_OCTAVE, INTERVAL_TYPES.UPWARD, noteFromOriginalInterval))
    }
  } else {
    result.push(keyObject)
    result.push(calculateNoteFromInterval(intervalName, intervalType, keyObject))
  }
  return result
}

const calculateNotesFromChord = function (chordName, keyObject, selectedSeventh) {
  const result = [keyObject]
  const chordIntervals = _.concat(CHORDS[chordName], SEVENTH_EXTRA_INTERVAL[selectedSeventh])
  if(!selectedSeventh) {
    console.warn('This should not happen')
  }

  if(!chordIntervals) return console.error('Incorred chord: ', chordName)
  _.forEach(chordIntervals, interval => {
    result.push(calculateNoteFromInterval(interval, INTERVAL_TYPES.UPWARD, keyObject))
  })
  return result
}

const calculateChordInversion = function (notesArray, selectedChordState) {
  let rotationNeeded = _.get(CHORD_STATES_ROTATIONS, selectedChordState)
  if (!rotationNeeded) return notesArray
  let unsortedResult = []
  unsortedResult = _.map(notesArray, (noteObject, index) => {
    if (index >= rotationNeeded) return noteObject
    return _.assign({}, noteObject, {octaveNumber: noteObject.octaveNumber + 1})
  })
  return _.sortBy(unsortedResult, ['octaveNumber', 'keyIndex'])
}

const calculateNotesFromScale = function (scaleName, keyObject) {
  const scaleIntervals = SCALES[scaleName] || GREEK_MODES[scaleName]
  if (!scaleIntervals) return console.error('Wrong scale', scaleIntervals)

  const result = [keyObject]
  _.forEach(scaleIntervals, interval => {
    result.push(calculateNoteFromInterval(interval, INTERVAL_TYPES.UPWARD, keyObject))
  })
  return result
}

module.exports = {
  calculateNoteFromInterval,
  calculateNotesFromInterval,
  calculateNotesFromChord,
  calculateNotesFromScale,
  calculateChordInversion,
  getInvertedInterval
}