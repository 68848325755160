<template>
  <Transition name="modal">
    <div
      v-if="show"
      class="modal-mask"
      @click="clickWrapper"
    >
      <div
        class="modal-container"
        :class="{'modal-container--large': isLarge}"
      >
        <font-awesome-icon
          class="modal-default-button"
          :icon="['fas', 'xmark']"
          @click="$emit('close')"
        />
        <div class="modal-header">
          <slot name="header"></slot>
        </div>

        <div class="modal-body">
          <slot name="body"></slot>
        </div>

        <div class="modal-footer">
          <slot name="footer">
          </slot>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
const _ = require('lodash')

export default {
  props: {
    show: String,
    isLarge: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    clickWrapper (event) {
      const hasToCloseModal = event.composedPath() && !_.some(event.composedPath(), element => {
        const classes = element.classList
        if (!classes) return false

        return classes.contains('modal-container')
      })

      if(hasToCloseModal) {
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}

.modal-container {
  width: 450px;
  margin: 0px auto;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  position:relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-height: 90%;
  background-color: var(--background_color);
  color: var(--text_color);
  &--large{
    width: 90%;
    max-width: 800px;
  }
}

.modal-header h3 {
  margin-top: 20px;
  color: var(--text_color);
}

.modal-body{
  flex-shrink: 1;
  min-height: 0;
  overflow: auto;
  padding: 0 30px 20px;
}

.modal-default-button {
  position:absolute;
  top: 20px;
  right: 20px;
  width: 15px;
  height: 20px;
  cursor: pointer;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>